import React, { Component } from "react";
import Pdflabel from "../assets/images/EPCHLabel.pdf";
import LabelPdfService from "../services/LabelPdfService";
import { Card } from "primereact/card";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class EPCHQR extends Component {
  constructor(props) {
    super(props);
    this.LabelPdfService = new LabelPdfService();
    this.state = {
      FromNo: 1,
      Qty: null,
      ToNo: null,
      Prefix: "EH",
    };
    this.submitForm = this.submitForm.bind(this);
  }

  async submitForm() {
    if (
      this.state.FromNo == "" ||
      this.state.ToNo == "" ||
      this.state.Qty == "" ||
      this.state.FromNo == undefined ||
      this.state.ToNo == undefined ||
      this.state.Qty == undefined
    ) {
      ////this.refs.Messchild.showWarn("Invalid Arguments");
    } else {
      let formData = {
        FromNo: this.state.FromNo,
        ToNo: this.state.ToNo,
      };
      await this.LabelPdfService.APICall(formData).then((response) => {
        this.LabelPdfService.BulkData(response.data.ResponseData);
      });
      toast.success("Downloaded Successfully");
      this.resetForm();
    }
  }

  resetForm() {
    this.setState({
      FromNo: "",
      ToNo: "",
      Qty: "",
    });
  }
  render() {
    return (
      <>
        <div className="container mt7">
          <div className="row justify-content-center newsletter-box pdflalel">
            <div className="col-md-6">
              <span className="anchor" id="formLogin" />
              {/* form card login */}
              <div className="card card-outline-secondary">
                <div className="card-header">
                  <h3 className="mb-0 d-flex  justify-content-center">
                    Print Label PDF
                  </h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="uname1">
                      From Number<span>*</span>
                    </label>
                    <input
                      type="number"
                      value={this.state.FromNo}
                      onChange={(e) =>
                        this.setState({
                          FromNo: e.target.value,
                          Qty: "",
                          ToNo: "",
                        })
                      }
                      className="form-control"
                      name="FromNo"
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      {" "}
                      Quantity<span>*</span>
                    </label>

                    <input
                      type="number"
                      value={this.state.Qty}
                      onChange={(e) =>
                        this.setState({
                          ToNo:
                            parseInt(e.target.value) +
                            parseInt(this.state.FromNo) -
                            1,
                          Qty: parseInt(e.target.value),
                        })
                      }
                      className="form-control"
                      name="Qty"
                    />
                  </div>
                  <div className="form-group">
                    <label>To Number</label>

                    <input
                      type="number"
                      value={this.state.ToNo}
                      className="form-control"
                      name="ToNo"
                      disabled
                    />
                  </div>
                  <div className=" d-flex justify-content-center">
                    <button
                      className="btn btn-primary mt-5"
                      onClick={this.submitForm}
                    >
                      Submit
                    </button>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
                    <input type="text" value={this.state.Qty}></input>
                    <span
                        onClick={() =>
                            this.PrintpdfDATA()
                        }
                    >
                        Download QR label
                    </span>
                    <Card>
                        <div className="row mb-3">
                            <div className="col-md-6 heading">
                                <h3>Print Label Pdf</h3>
                            </div>
                        </div>
                        <div className="usearea">
                            <div className="col-md-2 text-right mb-8 ">
                                <label>
                                    From Number<span>*</span>:
                                </label>
                            </div>
                            <div className="col-md-2 mb-2 ">
                                <input
                                    type="number"
                                    value={this.state.FromNo}
                                    onChange={(e) =>
                                        this.setState({ FromNo: e.target.value, Qty: "", ToNo: "" })
                                    }
                                    className="form-control"
                                    name="FromNo"
                                />
                            </div>
                            <div className="col-md-1 text-right    mb-8">
                                <label>
                                    Quantity<span>*</span>:
                                </label>
                            </div>
                            <div className="col-md-2 mb-2">
                                <input
                                    type="number"
                                    value={this.state.Qty}
                                    onChange={(e) =>
                                        this.setState({
                                            ToNo:
                                                parseInt(e.target.value) +
                                                parseInt(this.state.FromNo) -
                                                1,
                                            Qty: parseInt(e.target.value),
                                        })
                                    }
                                    className="form-control"
                                    name="Qty"
                                />
                            </div>
                            <div className="col-md-2 mb-2 ">
                                <label>
                                    To Number
                                </label>
                            </div>
                            <div className="col-md-2 ">
                                <input
                                    type="number"
                                    value={this.state.ToNo}
                                    className="form-control"
                                    name="ToNo"
                                    disabled
                                />
                            </div>
                            <div className="text-center my-5 ">
                                <button
                                    className="btn btn-primary mt-5"
                                    onClick={this.submitForm}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Card>
                </div> */}
      </>
    );
  }
}
