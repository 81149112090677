import React, { Component } from "react";
// import { Dialog } from 'primereact/dialog';
import "./Sidebar.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Chart } from "primereact/chart";
// import { FileUpload } from 'primereact/fileupload';
// import MessageComponent from '../template/Message';
// import logo from "./../assets/images/epch-logo.png";
import ScanQRService from "../../src/services/ScanQRService";
// import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
// import Logo from './../assets/images/epch-logo.png';
// import Avtar from './../assets/images/autar.png';
// // import { Toast } from "primereact/toast";
// // import { CSVLink } from "react-csv";
// import { InputText } from "primereact/inputtext";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { Button } from "primereact/button";
import tableRequest from "../models/tableRequest";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.ScanQRService = new ScanQRService();
    this.tblResponse = new tableRequest();
    this.GetDashBoardData = this.GetDashBoardData.bind(this);
    this.state = {
      totalMapped: "",
      totalDemapped: "",
      totalDamaged: "",
    };
  }

  componentDidMount() {
    this.GetDashBoardData();
  }

  GetDashBoardData() {
    const formData = {
      pageNo: 1,
      searchValue: "",
      pageSize: -1,
      sortColumn: "",
      sortOrder: "DESC",
      fromDate: "",
      toDate: "",
    };
    this.ScanQRService.GetDashBoardData(formData).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseData !== null) {
        this.setState({
          totalMapped: resdata.ResponseData.DashboardCount.TotalMapping,
          totalDemapped: resdata.ResponseData.DashboardCount.TotalDeMapping,
          totalDamaged: resdata.ResponseData.DashboardCount.TotalDamage,
        });
      }
    });
  }

  render() {
    const data = {
      labels: ["Damage", "Mapping", "Demapping"],
      datasets: [
        {
          label: "Damage",
          data: this.state.totalDamaged,
          fill: false,
          borderColor: "#c74e1a",
        },

        {
          label: "Product Mapping",
          data: this.state.totalMapped,
          fill: false,
          borderColor: "#97A5A5",
        },
        {
          label: "Product De-Mapping",
          data: this.state.totalDemapped,
          fill: false,
          borderColor: "#150e0b",
        },
      ],
    };
    return (
      <>
       <div className="row mt5">
        <div className="col-md-6">
        <div className="row cards dash-data ">
          <div className="col-sm-12">
            <div className="card no-shadow rm-border widget-chart text-left">
              <div className="icon-wrapper rounded-circle">
                <div className="icon-wrapper-bg opacity-10 bg-warning" />
                <i className="fa fa-cube text-white" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-subheading">Mapping</div>
                <div className="widget-numbers text-warning">
                  {this.state.totalMapped ? this.state.totalMapped : 0}
                </div>
              </div>
            </div>
            <div className="divider m-0 d-md-none d-sm-block" />
          </div>

          <div className="col-sm-12">
            <div className="card no-shadow rm-border widget-chart text-left">
              <div className="icon-wrapper rounded-circle">
                <div className="icon-wrapper-bg opacity-9 bg-primary" />
                <i className="fa fa-signal text-white" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-subheading">De Mapping</div>
                <div className="widget-numbers text-primary">
                  {" "}
                  {this.state.totalDemapped ? this.state.totalDemapped : 0}
                </div>
              </div>
            </div>
            <div className="divider m-0 d-md-none d-sm-block" />
          </div>

          <div className="col-sm-12">
            <div className="card no-shadow rm-border  widget-chart text-left">
              <div className="icon-wrapper rounded-circle">
                <div className="icon-wrapper-bg opacity-9 bg-danger" />
                <i className="fa fa-trash text-white" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-subheading">Damage</div>
                <div className="widget-numbers text-danger">
                  {" "}
                  {this.state.totalDamaged ? this.state.totalDamaged : 0}
                </div>
              </div>
            </div>
            <div className="divider m-0 d-md-none d-sm-block" />
          </div>
        </div>
        </div>

        <div className="col-md-6">
        <div className="cards flex justify-content-center dash-data">
          <Chart
            type="doughnut"
            data={{
              labels: ["Total Mapped", "Total Demapped", "Total Damaged"],
              datasets: [
                {
                  data: [
                    this.state.totalMapped,
                    this.state.totalDemapped,
                    this.state.totalDamaged,
                  ],
                  backgroundColor: ["#f7b924", "#007bff", "#dc3545"],
                  hoverBackgroundColor: ["#f7b924", "#007bff", "#dc3545"],
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: "#495057",
                  },
                },
              },
            }}
            style={{ position: "relative", width: "100%" }}
          />
        </div>
        </div>
      </div>
        


      

        {/* <div className="full-width">
          <Chart height="100" type="line" data={data} />
        </div> */}
        <ToastContainer />
      </>
    );
  }
}
