// request for lazy loading for all pages

export default class tableRequest {
    IsActive = true;
    PageNo = 1;
    First = 1;
    SearchValue = "";
    PageSize = 1000000000;
    SortColumn = "";
    SortOrder = "DESC";
    ProductId = 0;
    WareHouseId = 0;
    UserId = 0;
    FromDate = "";
    ToDate = "";
    UserType = 0;
    IsAccepted = false; //for stock use
    IsWareHouse = true;
    Prefix = "";
    isPreprinted = false;
    Qty = 0;
}