import axios from "axios";
export default class DeMappingService {
  ControllerName = "DeMapping";

  // delete user data
  InActiveRow(EPCHID) {
    ;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/InActiveRow/${EPCHID}`
      )
      .then((res) => res);
  }

  CreateDemap(data) {
    ;
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateDemap`,
        data
      )
      .then((res) => res);
  }
}
