import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import tableRequest from "../../models/tableRequest";
import ScanQRService from "../../services/ScanQRService";
import DeMappingService from "../../services/DeMappingService";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BreadCrumb } from "primereact/breadcrumb";
export default class DeMapping extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.ScanQRService = new ScanQRService();
    this.DeMappingService = new DeMappingService();
    this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.state = {
      QRData: "",
      SeqNo: "",
      demapping: [],
      TestPerformed: "",
      UniqueCode: "",
      BarCode: "",
      Weaving: "",
      IsActive: true,
      ProductImg: "",
      IsDamaged: true,
      IsDemapped: true,
      ProductCode: "",
      SupplierCode: "",
      Colour: "",
      Embroidery: "",
    };
  }

  componentDidMount() {
    this.GetProducts();
  }
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetProducts();
    }, 100);
  }

  // Get Products list
  GetProducts() {
    this.tblResponse.SortColumn = "CreatedDt";

    this.ScanQRService.GetEPCHData(this.tblResponse).then((data) => {
      const resdata = data.data;
      // this.setState({ updateLoader: true });
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          demapping: this.datasource.slice(),
          loading: false,
        });
      } else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          epchData: null,
          loading: false,
        });
      }
    });
  }

  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      const formData = {
        SeqNo: rowData.SeqNo,
      };
      this.DeMappingService.CreateDemap(formData).then((data) => {
        const resdata = data.data;
        if (resdata.ResponseMessage == "Demapped successfully") {
          toast.success("Demapped Successfully");
          this.GetProducts();
        } else {
          toast.error("Already Demapped");
        }
      });
    };
    const submit = () => {
      confirmAlert({
        title: "Confirmation",
        message: "Are you sure you want to Demap.",
        buttons: [
          {
            label: "Yes",
            onClick: () => editMode(),
          },
          {
            label: "No",
          },
        ],
      });
    };

    return (
      <span>
          <Button
          type="button"
          label="Demap"
          onClick={submit}
          className="p-button-danger"
          style={{ marginRight: "3px" }}
        ></Button>
       </span>
    );
  }

  // search filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;

    this.GetProducts();
  }

  // on sort table
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetProducts();
  }

  // use for image
  brandTemplate(rowData, column) {
    var src = rowData.ProductImg;
    return <img src={src} width="30" />;
  }

  render() {
    const items = [{ label: "Masters" }, { label: "Demapping" }];
   
    const home = { icon: "pi pi-home", url: "/dashboard" };
    return (
      <>
       
        <ToastContainer />
        <Card className="mt5">
          <div className="row ">
            <div className="col-md-6 heading ">
              <h3>Demapping</h3>
            </div>
            <div className="col-md-6 custom-breadcrumbn">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <DataTable
           responsive={true}
        
            style={{ width: "100%" }}
            scrollable
            value={this.state.demapping}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            onsort={this.onSort}
          >
            <Column field="SeqNo" header="Sequence No" sortable={true} />
            <Column field="UniqueCode" header="Unique Code" sortable={true} />
            <Column field="ProductCode" header="Product" sortable={true} />
            <Column field="SupplierCode" header="Supplier" sortable={true} />
            <Column field="BarCode" header="BarCode" sortable={true} />

            {/* <Column field="productCd" header="Product Code" sortable={true} />
                    <Column field="prodSpecification" header="Product Specification" sortable={true} /> */}
            <Column field="Colour" header="Colour" />
            <Column field="Weaving" header="Weaving" />
            <Column field="Embroidery" header="Embroidery" />
            <Column field="TestPerformed" header="Test Performed" />
            <Column
              field="ProductImg"
              // style={{ width: "100px" }}
              header="Product Image"
              body={this.brandTemplate}
            />
            {/* <Column body={this.activeTemplate} header="Status" /> */}
            <Column body={this.actionTemplate} header="Action" />
          </DataTable>
        </Card>
      </>
    );
  }
}
