import axios from "axios";
export default class UserService {
  ControllerName = "User";

  // get all user data
  GetUserMasterDetails(tableRequest) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUserMasterDetails`,
        tableRequest
      )
      .then((res) => res);
  }
  // create new user
  CreateUpdateUser(Payload) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUser`,
        Payload
      )
      .then((res) => res);
  }

  // delete user data
  // DeleteUser(UserId) {
  //         return axios.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteUser/${UserId}`).then(res => res);
  // }
  // updat profile
  async UpdateProfile(Payload) {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}${this.ControllerName}/UpdateUser`,
      Payload
    );
    return res;
  }

  // user list for ddl
  GetUsersDDL() {
    return axios
      .get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUsersDDL`)
      .then((res) => res);
  }

  // get user data by id
  //   getUser() {
  //     return axios.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUserMasterDetails`)
  //     .then(res => res);
  // }
  // user list for ddl
  getUserMenu(userId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUserMenu?userId=${userId}`
      )
      .then((res) => res);
  }

}
