import axios from 'axios';

export default class PAService {
    ControllerName = 'Auth';

    // create new user 
    VerifyProduct(rndmNo) {
        
        return axios.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/VerifyProduct`, rndmNo).then(res => res)
    }
}



