import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import PrivacyPolicy from './views/PrivacyPolicy';
import "bootstrap/dist/js/bootstrap.bundle.js";
import Login from './views/Login';
import React, { Component } from "react";
import Layout from './template/Layout';
import ProductAuthentication from './views/Pa';
import Footer from "./template/Footer";
// import Profile from './views/Profile';
import Dashboard from './views/Dashboard';

const rootElement = document.getElementById("root");

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      authPage: 'other'
    }
  }

  componentDidMount() {

    const urlParams = window.location.pathname;
    if (urlParams === '/privacy-policy') {
      this.setState({
        authPage: 'privacy-policy'
      })
    }
    else if (urlParams === '/pa' || urlParams === '/pa') {
      this.setState({
        authPage: 'pa'
      })
    }
    else {
      this.setState({
        authPage: 'other'
      })
    }
    if (this.state.authPage === 'other') {
      let storeCollector = JSON.parse(sessionStorage.getItem("LoggedInUser"));
      if (storeCollector && storeCollector.login) {
        this.setState({
          login: true
        })
      } else {
        this.setState({
          login: false
        })
      }
    }
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="login" element={<Login />} />
          </Routes>
        </BrowserRouter>,
        {this.state.authPage === 'pa' ? <ProductAuthentication /> : (this.state.login ? <><Layout /><Footer /> </> : <Login />)}
      </>)
  }

}
