import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Dashboard from "./../views/Dashboard";
import AccessRight from "./../views/masters/AccessRight";
import UserMaster from "./../views/masters/UserMaster";
import Mapping from "./../views/transaction/Mapping";
import DeMapping from "./../views/transaction/DeMapping";
import Damage from "./../views/transaction/Damage";
import MappingTestReport from "./../views/reports/MappingTestReport";
import DemappingReport from "./../views/reports/DemappingReport";
import DamageReport from "./../views/reports/DamageReport";
import DownloadReport from "./../views/reports/DownloadReport";
import Logo from "./../assets/images/epch-logo.png";
import Avtar from "./../assets/images/avatar.jpg";
// import Profile from '../../views/Profile';
import Message from "./Message";
import UserService from "../services/UserService";

import EPCHQR from "../views/EPCHQR";
import EPCHQROutline from "../views/EPCHQROutline";
import EPCHQRFake from "./../views/EPCHQRFake";

import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Message from './Message';
// import Damage from "../views/Damage";

export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.UserService = new UserService();
    this.state = {
      UserName: "",
      toggle: false,
      value: null,

      menuItems: [],
      demoMenu: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          url: "/Dashboard",
        },
        {
          label: "Masters",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "User Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/user-master",
            },
            {
              label: "Role Management",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/AccessRight/",
            },
          ],
        },
        {
          label: "Transactions",
          icon: "pi pi-fw pi-pencil",
          items: [
            {
              label: "Mapping",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/mapping/",
            },
            {
              label: "Number Generation ",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/deMapping/",
            },
            {
              label: "damage",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/damage/",
            },
          ],
        },
        {
          label: "Reports",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Demapping-report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/Demapping-report/",
            },
            {
              label: "Damage Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/Damage-report/",
            },
            {
              label: "MappingTest Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/MappingTest-report/",
            },
            {
              label: "Download Certificate",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/Download-report/",
            },
          ],
        },
        {
          label: "EPCHQR",
          icon: "pi pi-fw pi-home",
          url: "/Label PDF",
        },
        {
          label: "EPCHQRFake",
          icon: "pi pi-fw pi-home",
          url: "/FakeLabel PDF",
        },
      ],
    };

    //this.UserService = new UserService();
  }
  //trigger api function on page call
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      // this.CheckIsResetPassword();
      // this.getUser();
    }, 1000);
  }

  //to get user data
  //  getUser() {
  //   this.UserService.getUser().then(data => {
  //     const resdata = data.data;
  //
  //     if (resdata.ResponseCode === '200') {
  //       const usr = resdata.ResponseData;
  //       this.setState({
  //         UserName: usr.Username,
  //         UserId: usr.UserId
  //       });
  //       sessionStorage.setItem("wid", usr.WareHouseId);
  //       sessionStorage.setItem("uid", usr.UserId);
  //     }
  //   });
  // }
  // Check Reset Password
  // CheckIsResetPassword() {
  //   this.UserService.getUser().then((res) => {

  //     const curl = window.location.pathname;

  //     const resdata = res.data;
  //     //if ResponData not null
  //     if (resdata !== null && resdata.ResponseCode === '200' && resdata.ResponseData != null && resdata.ResponseData.IsResetPassword === true) {
  //       if (!curl.includes("/profile")) {
  //         window.location = '/profile';
  //       }
  //     } else {

  //     }
  //   });
  // }

  handleChange = (value) => {
    this.setState({ value });
  };

  // // get Menu by User
  // getMenu() {
  //   var userId = sessionStorage.getItem("UserId");
  //   this.UserService.getUserMenu(userId).then((data) => {
  //
  //     let childcheck = [];
  //     //if ResponseCode is 200
  //     if (data.data.ResponseCode === "200") {
  //       const arraydata = data.data.ResponseData;
  //       let arryFinal = [];
  //       //if arraydata not null
  //       if (arraydata != null)
  //         // //console.log("Server Res");
  //         //  //console.log(arraydata);
  //         arraydata.forEach((element) => {
  //           let contents = {};
  //           let obj = {};
  //           element.content.forEach((econ) => {
  //             obj.label = econ.name;
  //             obj.icon = econ.icon;
  //             obj.url = econ.to;

  //             if (econ.children != null && econ.children.length > 0) {
  //               let chlddata = [];

  //               econ.children.forEach((econchld) => {
  //                 let ob = {};

  //                 econchld.content.forEach((element) => {
  //                   ob.label = element.name;
  //                   ob.url = element.to;
  //                   childcheck.push(element.to);
  //                 });

  //                 chlddata.push(ob);
  //               });

  //               obj.items = chlddata;
  //             }
  //             contents = obj;
  //           });

  //           arryFinal.push(contents);

  //           //  //console.log(element.content)
  //         });
  //       //console.log(arryFinal);
  //       this.setState({
  //         menuItems: arryFinal,
  //       });
  //       ;
  //       //if window.location.pathname not valid
  //       if (
  //         childcheck.find((x) => x === window.location.pathname) ===
  //           undefined &&
  //         window.location.pathname != "/dashboard" &&
  //         window.location.pathname != "/profile" &&
  //         window.location.pathname != "/change-password"

  //       ) {
  //         sessionStorage.removeItem("LoggedInUser");
  //         sessionStorage.removeItem("refreshToken");
  //         sessionStorage.removeItem("token");
  //         sessionStorage.removeItem("userType");
  //         sessionStorage.removeItem("wareHouseId");
  //         window.location = "/login";
  //       }
  //     } else {
  //       sessionStorage.removeItem("LoggedInUser");
  //       sessionStorage.removeItem("refreshToken");
  //       sessionStorage.removeItem("token");
  //       sessionStorage.removeItem("userType");
  //       sessionStorage.removeItem("wareHouseId");

  //       window.location = "/login";
  //     }
  //   });
  // }

  // Render HTML
  //updated  Render HTML to resolve defect KREP-12 on [08-07-20]
  render() {
    return (
      <>
        <div className={this.state.toggle ? "row1 open" : "row1"}>
          <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="container-fluid">
              <a href="/dashboard" className="navbar-brand">
                <img src={Logo} alt="EPCH" className="img-fluid epchlogo" />
              </a>

              <div id="navbarCollapse" className="">
                <ul className="navbar-nav ml-auto">
                  <div className="right-section">
                    <div className="user-area dropdown">
                      <img
                        alt="User Avatar"
                        className="user-avatar rounded-circle"
                        src={Avtar}
                      />
                      <span className="dropdown-toggle active ml-2">
                        Welcome, {sessionStorage.getItem("UserName")}
                      </span>
                      <div className="user-menu">
                        <div
                          className="nav-link"
                          onClick={(e) => {
                            sessionStorage.removeItem("LoggedInUser");
                            window.location = "/login";
                          }}
                        >
                          <i className="fa fa-power-off"></i>Logout
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </nav>

          <div className="menu-bar">
            <Sidebar />
          </div>
          <div className="full-width">
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/labelpdf" element={<EPCHQR />} />
                <Route exact path="/labelFakepdf" element={<EPCHQRFake />} />
                <Route path="/masters/user-master" element={<UserMaster />} />
                <Route path="/masters/AccessRight" element={<AccessRight />} />
                <Route path="/transaction/mapping" element={<Mapping />} />
                <Route path="/transaction/deMapping" element={<DeMapping />} />
                <Route path="/transaction/damage" element={<Damage />} />
                <Route
                  path="/reports/Demapping-report"
                  element={<DemappingReport />}
                />
                <Route
                  path="/reports/Damage-report"
                  element={<DamageReport />}
                />
                <Route
                  path="/reports/MappingTest-report"
                  element={<MappingTestReport />}
                />
                <Route
                  path="/reports/Download-report"
                  element={<DownloadReport />}
                />

                <Route path="*" element={<Dashboard />} />
              </Routes>
            </BrowserRouter>
          </div>
          <div className="Message">
            <Message />
          </div>
        </div>
      </>
    );
  }
}
