import React, { Component } from "react";
// import { PanelMenu } from 'primereact/panelmenu';
import UserService from "../services/UserService";
//  import MultilevelSidebar from "react-multilevel-sidebar";
import { Menubar } from "primereact/menubar";

// import "react-multilevel-sidebar/src/Sidebar.css";

let options = [];

// Side bar creation
export class Sidebar extends Component {
  constructor() {
    super();

    this.UserService = new UserService();
    this.state = {
      isOpen: true,
      isPersist: false,
      menuItems: [],
      demoMenu: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          url: "/Dashboard",
        },
        {
          label: "Masters",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "User Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/user-master",
            },
            {
              label: "Access Right",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/AccessRight/",
            },
          ],
        },
        {
          label: "Transactions",
          icon: "pi pi-fw pi-pencil",
          items: [
            {
              label: "Mapping",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/mapping/",
            },
            {
              label: "Number Generation ",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/deMapping/",
            },
            {
              label: "damage",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/damage/",
            },
          ],
        },
        {
          label: "Reports",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Demapping-report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/Demapping-report/",
            },
            {
              label: "Damage-report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/Damage-report/",
            },
            {
              label: "MappingTest Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/MappingTest-report/",
            },
            {
              label: "Download Certificate",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/Download-report/",
            },
          ],
        },
        {
          label: 'EPCHQR',
          icon: 'pi pi-fw pi-home',
          url: '/labelpdf'
       },
        
        {
          label: 'EPCHQRFakeQR',
          icon: 'pi pi-fw pi-home',
          url: '/labelFakepdf'
       },
      ],
    };
  }
  //to handle sidebar toggle
  handleSidebarToggle = (isOpen) => {
    this.setState({ isOpen });
  };
  //trigger api function on page call
  componentDidMount() {
    this.getMenu();
  }

  // // get Menu by User
  getMenu() {
    ;
    var userId = sessionStorage.getItem("UserId");
    this.UserService.getUserMenu(userId).then((data) => {
      ;
      let childcheck = [];
      //if ResponseCode is 200
      if (data.data.ResponseCode === "200") {
        const arraydata = data.data.ResponseData;
        let arryFinal = [];
        //if arraydata not null
        if (arraydata != null)
          // //console.log("Server Res");
          //  //console.log(arraydata);
          arraydata.forEach((element) => {
            let contents = {};
            let obj = {};
            element.content.forEach((econ) => {
              obj.label = econ.name;
              obj.icon = econ.icon;
              obj.url = econ.to;

              if (econ.children != null && econ.children.length > 0) {
                let chlddata = [];

                econ.children.forEach((econchld) => {
                  let ob = {};

                  econchld.content.forEach((element) => {
                    ob.label = element.name;
                    ob.url = element.to;
                    childcheck.push(element.to);
                  });

                  chlddata.push(ob);
                });

                obj.items = chlddata;
              }
              contents = obj;
            });
            arryFinal.push(contents);
            //  //console.log(element.content)
          });
        //console.log(arryFinal);
        this.setState({
          menuItems: arryFinal,
        });
        ;
        //if window.location.pathname not valid
        if (
          childcheck.find((x) => x === window.location.pathname) ===
            undefined &&
          window.location.pathname != "/dashboard" 
          &&
          window.location.pathname != "/labelpdf"
          &&
          window.location.pathname != "/labelFakepdf"
          //  window.location.pathname != "/profile" &&
          //  window.location.pathname != "/change-password"
        ) {
          sessionStorage.removeItem("LoggedInUser");
          sessionStorage.removeItem("refreshToken");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userType");
          sessionStorage.removeItem("wareHouseId");
          window.location = "/login";
        }
      } else {
        sessionStorage.removeItem("LoggedInUser");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userType");
        sessionStorage.removeItem("wareHouseId");

        window.location = "/login";
      }
    });
  }

  stripUndefined(arr) {
    return arr.reduce(function (result, item) {
      result.push(
        Array.isArray(item) && !item.length ? this.stripUndefined(item) : item
      );
      return result;
    }, []);
  }

  // Return HTML of Side bar

  render() {
    const menuItems = this.state.menuItems;
    //////console.log(this.state.demoMenu);

    //     const sidebarMenu = menuItems.map((items, key) => {
    //     const subMenu = items.items;
    //     <li key={key}><a>{items.label}</a>
    //     {/* {subMenu.map((subItem, key) => <li>
    //         {subMenu.label}
    //     </li>)} */}
    //     </li>
    // })

    return (
      <div className="sidebar-wraper">
        <Menubar model={this.state.menuItems} />
      </div>
    );
  }
}

export default Sidebar;
