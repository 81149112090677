import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FileUpload } from "primereact/fileupload";
import MessageComponent from "../../template/Message";
// import logo from "./../assets/images/epch-logo.png";
import ScanQRService from "../../services/ScanQRService";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Logo from ".././../assets/images/epch-logo.png";
import Avtar from ".././../assets/images/autar.png";
// import { Toast } from "primereact/toast";
// import { CSVLink } from "react-csv";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import tableRequest from "../../models/tableRequest";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../assets/images/edit.png";

export default class Mapping extends Component {
  constructor(props) {
    super(props);
    this.ScanQRService = new ScanQRService();
    this.tblResponse = new tableRequest();
    this.actionTemplate = this.actionTemplate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.brandTemplate = this.brandTemplate.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.hidedisplaymodelFunc = this.hidedisplaymodelFunc.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitQRDetails = this.submitQRDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.resetForm = this.resetForm.bind(this);

    this.state = {
      Dashdata: false,
      updateLoader: false,
      isAdmin: false,
      displayDialog: false,
      user: [],
      displaymodel: false,
      invalidMessage: "",
      buttonLabel: "Submit",
      Embroideries: [
        { key: "E0", value: "E0" },
        { key: "E1", value: "E1" },
        { key: "E2", value: "E2" },
        { key: "E3", value: "E3" },
        { key: "E4", value: "E4" },
        { key: "E5", value: "E5" },
        { key: "E6", value: "E6" },
        { key: "E6+", value: "E6+" },
        { key: "Others", value: "other" },
      ],
      isIEmbroidery: true,
      IEmbroidery: "",
      Embroidery: "",

      UniqueCode: "",
      isUniqueCode: true,
      IUniqueCode: "",
      UniqueCodelist: [
        { key: "WII-PCC", value: "WII-PCC" },
        { key: "Others", value: "other" },
      ],
      Weaving: "",
      isWeaving: true,
      IWeaving: "",
      Weavinglist: [
        { key: "Plain", value: "Plain" },
        { key: "Mosaic", value: "Mosaic" },
        { key: "Chevron", value: "Chevron" },
        { key: "Criss-Cross", value: "Criss-Cross" },
        { key: "Others", value: "other" },
      ],
      ProductCode: "",
      isProductCode: true,
      IProductCode: "",
      ProductCodeList: [
        { key: "Shawl", value: "Shawl" },
        { key: "Dushala Shawl", value: "Dushala Shawl" },
        { key: "Stole", value: "Stole" },
        { key: "Muffler", value: "Muffler" },
        { key: "Others", value: "other" },
      ],

      Colour: "other",
      IColour: "",
      isColour: false,
      Colourlist: [{ key: "Others", value: "other" }],

      TestPerformed: "",
      ITestPerformed: "",
      TestPerformedlist: [
        {
          key: "Physical & Light Microscopy",
          value: "Physical & Light Microscopy",
        },
        { key: "Others", value: "other" },
      ],
      isTestPerformed: true,
      QRData: "",
      SeqNo: 0,
      epchData: [],
      totalRecords: 0,
      first: 0,
      loading: true,
      VisibleNo: "",
      EPCHID: 0,
      ProductImg: "",
      Weight: "",
      Dimensions: "",
      BatchCode: "",
      SupplierCode: "",
      BarCode: "",
      IsActive: "",
      EncryptedValue: "",
      fakeVerify: false,
      formError: {
        QRData: "",
        SeqNo: 0,
        UniqueCode: "",
        ProductCode: "",
        Colour: "",
        IColour: "",
        Embroidery: "",
        IEmbroidery: "",
        Weaving: "",
        IWeaving: "",
        TestPerformed: "",
        ITestPerformed: "",
        ProductCode: "",
        UniqueCode: "",
        IUniqueCode: "",
        Weight: "",
        Dimensions: "",
        BatchCode: "",
        SupplierCode: "",
        BarCode: "",
        IsActive: "",
        EncryptedValue: "",
        ProductImg: "",
        noOfCount: "",
      },
    };

    // this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    // setTimeout(() => {
    //
    this.GetProducts();
    this.GetCounts();
    this.setState({ userEmail: sessionStorage.getItem("Email") });
    // }, 1000);
  }

  GetCounts() {
    const formData = {
      pageNo: 1,
      searchValue: "",
      pageSize: -1,
      sortColumn: "",
      sortOrder: "DESC",
      fromDate: "",
      toDate: "",
    };
    this.ScanQRService.GetDashBoardData(formData).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseData !== null) {
        this.setState({
          noOfCount: resdata.ResponseData.DashboardCount.TotalMapping,
        });
      }
    });
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayDialog: false,
    });
  }

  //to hide function
  hidedisplaymodelFunc() {
    this.setState({
      displaymodel: false,
      QRData: "",
    });
  }

  // set Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.ProductImg = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              ProductImg: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.ProductImg = "Only image format is accepted.";
            this.setState({
              ProductImg: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  onErrorImage() {}

  //open dialog
  openDialog() {
    this.setState({ visible: true });
    this.formForgotReset();
  }

  async submitForm(event) {
    debugger;
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      const ResponseData = await this.ScanQRService.ScanQRCode(
        this.state.QRData
      );
      const authResponse = ResponseData.data;
      const authData = authResponse?.ResponseData;
      if (authResponse.ResponseCode === "200") {
        this.GetProducts();
        this.GetCounts();
        // this.resetForm();
        this.setState({
          VisibleNo: authData.VisibleNo,
          displayDialog: false,
          displaymodel: true,
        });
      } else if (authResponse.ResponseCode === "400") {
        toast.warning(authResponse.ResponseMessage);
      } else {
        toast.error(authResponse.ResponseMessage);
      }
    } else {
      toast.warning("Please Scan/Enter QR Code");
    }
  }

  //to validate form data
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    let randomNo = parseFloat(this.state.QRData);

    switch (name) {
      case "QRData":
        if (value.length < 1) {
          IsValid = false;
          this.display = false;

          errors.QRData = "Please Scan QR Code";
        } else errors.QRData = "";
        break;

      default:
        break;
    }
    return IsValid;
  }

  //to validate form data
  validateSubForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "TestPerformed":
        debugger;
        if (value.length < 1) {
          IsValid = false;
          errors.TestPerformed = "Please Select Test Performed Value.";
        } else errors.TestPerformed = "";
        break;
      case "ITestPerformed":
        if (this.state.TestPerformed == "other")
          if (value.length < 1) {
            IsValid = false;
            errors.ITestPerformed = "Please Enter TestPerformed  Value.";
          } else errors.ITestPerformed = "";
        break;

      case "Weight":
        if (value.length < 1) {
          IsValid = false;
          errors.Weight = "Please Enter Weight Value.";
        } else errors.Weight = "";
        break;

      case "Dimensions":
        if (value.length < 1) {
          IsValid = false;
          errors.Dimensions = "Please Enter Dimensions Value.";
        } else errors.Dimensions = "";
        break;

      case "BatchCode":
        if (value.length < 1) {
          IsValid = false;
          errors.BatchCode = "Please Enter BatchCode Value.";
        } else errors.BatchCode = "";
        break;

      case "SupplierCode":
        if (value.length < 1) {
          IsValid = false;
          errors.SupplierCode = "Please Enter SupplierCode Value.";
        } else errors.SupplierCode = "";
        break;

      case "BarCode":
        if (value.length < 1) {
          IsValid = false;
          errors.BarCode = "Please Enter BarCode Value.";
        } else errors.BarCode = "";
        break;

      case "Embroidery":
        if (value.length < 1) {
          IsValid = false;
          errors.Embroidery = "Please Select Embroidery Value.";
        } else errors.Embroidery = "";
        break;
      case "IEmbroidery":
        if (this.state.Embroidery == "other")
          if (value.length < 1) {
            IsValid = false;
            errors.IEmbroidery = "Please Enter Embroidery  Value.";
          } else errors.IEmbroidery = "";
        break;
      case "Weaving":
        if (value.length < 1) {
          debugger;
          IsValid = false;
          errors.Weaving = "Please Select Weaving Value.";
        } else errors.Weaving = "";
        break;
      case "IWeaving":
        debugger;
        if (this.state.Weaving == "other")
          if (value.length < 1) {
            IsValid = false;
            errors.IWeaving = "Please Enter Weaving Value.";
          } else errors.IWeaving = "";
        break;
      case "Colour":
        if (value.length < 1) {
          IsValid = false;
          errors.Colour = "Please Enter Colour Value.";
        } else errors.Colour = "";
        break;
      case "IColour":
        if (this.state.Colour == "other")
          if (value.length < 1) {
            IsValid = false;
            errors.IColour = "Please Enter Colour  Value.";
          } else errors.IColour = "";
        break;
      case "ProductImg":
        if (value.length < 1) {
          IsValid = false;
          errors.ProductImg = "Please Upload Product Image";
        } else errors.ProductImg = "";
        break;
      case "ProductCode":
        if (value.length < 1) {
          IsValid = false;
          errors.ProductCode = "Please Select ProductCode Value.";
        } else errors.ProductCode = "";
        break;
      case "IProductCode":
        if (this.state.ProductCode == "other")
          if (value.length < 1) {
            IsValid = false;
            errors.IProductCode = "Please Enter ProductCode  Value.";
          } else errors.IProductCode = "";
        break;
      case "UniqueCode":
        if (value.length < 1) {
          IsValid = false;
          errors.UniqueCode = "Please Select UniqueCode Value.";
        } else errors.UniqueCode = "";
        break;
      case "IUniqueCode":
        if (this.state.UniqueCode == "other")
          if (value.length < 1) {
            IsValid = false;
            errors.IUniqueCode = "Please Enter UniqueCode  Value.";
          } else errors.IUniqueCode = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  //to handle form
  handleChange(event) {
    let { name, value } = event.target;
    let errors = this.state.formError;
    this.validateSubForm(value, name);
    this.setState({ errors, [name]: value });
  }

  async submitQRDetails(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateSubForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
      return isfrmvalid;
    });
    if (isfrmvalid) {
      const formData = {
        encryptedValue: this.state.QRData,
        seqNo: this.state.VisibleNo,
        uniqueCode:
          this.state.UniqueCode == "other"
            ? this.state.IUniqueCode
            : this.state.UniqueCode,
        productCode:
          this.state.ProductCode == "other"
            ? this.state.IProductCode
            : this.state.ProductCode,
        productImg: this.state.ProductImg,
        colour:
          this.state.Colour == "other" ? this.state.IColour : this.state.Colour,
        // event.globalFilter ? event.globalFilter : "";
        weaving:
          this.state.Weaving == "other"
            ? this.state.IWeaving
            : this.state.Weaving,
        embroidery:
          this.state.Embroidery == "other"
            ? this.state.IEmbroidery
            : this.state.Embroidery,
        testPerformed:
          this.state.TestPerformed == "other"
            ? this.state.ITestPerformed
            : this.state.TestPerformed,
        weight: this.state.Weight,
        dimensions: this.state.Dimensions,
        batchCode: this.state.BatchCode,
        supplierCode: this.state.SupplierCode,
        barCode: this.state.BarCode,
        fakeVerify: this.state.fakeVerify,
        CreatedBy: sessionStorage.getItem("UserId"),
      };

      const ResponseData = await this.ScanQRService.CreatetvpScannedQR(
        formData
      ).then((data) => {
        const resdata = data.data;
        if (resdata.ResponseCode == "200") {
          toast.success("Submitted Successful");
          this.resetForm();
          this.GetProducts();
          this.GetCounts();
          this.hidedisplaymodelFunc();
        } else if (resdata.ResponseCode == "400") {
          toast.error(resdata.ResponseMessage);
        } else {
          toast.error(resdata.ResponseMessage);
        }
      });
    }
  }

  resetForm() {
    this.setState({
      Dashdata: false,
      updateLoader: false,
      isAdmin: false,
      displayDialog: false,
      user: [],
      displaymodel: false,
      invalidMessage: "",
      buttonLabel: "Submit",
      Embroideries: [
        { key: "E0", value: "E0" },
        { key: "E1", value: "E1" },
        { key: "E2", value: "E2" },
        { key: "E3", value: "E3" },
        { key: "E4", value: "E4" },
        { key: "E5", value: "E5" },
        { key: "E6", value: "E6" },
        { key: "E6+", value: "E6+" },
        { key: "Others", value: "other" },
      ],
      isIEmbroidery: true,
      IEmbroidery: "",
      Embroidery: "",

      UniqueCode: "",
      isUniqueCode: true,
      IUniqueCode: "",
      UniqueCodelist: [
        { key: "WII-PCC", value: "WII-PCC" },
        { key: "Others", value: "other" },
      ],
      Weaving: "",
      isWeaving: true,
      IWeaving: "",
      Weavinglist: [
        { key: "Plain", value: "Plain" },
        { key: "Mosaic", value: "Mosaic" },
        { key: "Chevron", value: "Chevron" },
        { key: "Criss-Cross", value: "Criss-Cross" },
        { key: "Others", value: "other" },
      ],
      ProductCode: "",
      isProductCode: true,
      IProductCode: "",
      ProductCodeList: [
        { key: "Shawl", value: "Shawl" },
        { key: "Dushala Shawl", value: "Dushala Shawl" },
        { key: "Stole", value: "Stole" },
        { key: "Muffler", value: "Muffler" },
        { key: "Others", value: "other" },
      ],

      Colour: "other",
      IColour: "",
      isColour: false,
      Colourlist: [{ key: "Others", value: "other" }],

      TestPerformed: "",
      ITestPerformed: "",
      TestPerformedlist: [
        {
          key: "Physical & Light Microscopy",
          value: "Physical & Light Microscopy",
        },
        { key: "Others", value: "other" },
      ],
      isTestPerformed: true,
      QRData: "",
      SeqNo: 0,
      epchData: [],
      totalRecords: 0,
      first: 0,
      loading: true,
      VisibleNo: "",
      EPCHID: 0,
      ProductImg: "",
      Weight: "",
      Dimensions: "",
      BatchCode: "",
      SupplierCode: "",
      BarCode: "",
      IsActive: "",
      EncryptedValue: "",
      fakeVerify: false,
      formError: {
        QRData: "",
        SeqNo: 0,
        UniqueCode: "",
        ProductCode: "",
        Colour: "",
        IColour: "",
        Weaving: "",
        Embroidery: "",
        Weaving: "",
        IEmbroidery: "",
        TestPerformed: "",
        ProductCode: "",
        UniqueCode: "",
        Weight: "",
        Dimensions: "",
        BatchCode: "",
        SupplierCode: "",
        BarCode: "",
        IsActive: "",
        EncryptedValue: "",
        ProductImg: "",
        noOfCount: "",
      },
    });
  }

  // initialize page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetProducts();
    }, 100);
  }

  // get list of data
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // Get Products list
  GetProducts() {
    this.tblResponse.SortColumn = "CreatedDt";

    this.ScanQRService.GetEPCHData(this.tblResponse).then((data) => {
      const resdata = data.data;
      // this.setState({ updateLoader: true });
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          epchData: this.datasource.slice(),
          loading: false,
        });
      } else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          epchData: null,
          loading: false,
        });
      }
    });
  }

  // search filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;

    this.GetProducts();
  }
  // on sort table
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetProducts();
  }
  // use for image
  brandTemplate(rowData, column) {
    var src = rowData.ProductImg;
    return <img className="zoom" src={src} width="45" height={45} />;
  }

  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      debugger;
      var temp = this.state.Embroideries.find(
        (el) => el.value === rowData.Embroidery
      );
      var Embroidery = "";
      if (temp == null || temp == undefined) {
        Embroidery = "other";
      }

      var UniqueCode = "";
      temp = this.state.UniqueCodelist.find(
        (el) => el.value === rowData.UniqueCode
      );
      if (temp == null || temp == undefined) {
        UniqueCode = "other";
      }

      var ProductCode = "";
      temp = this.state.ProductCodeList.find(
        (el) => el.value === rowData.ProductCode
      );
      if (temp == null || temp == undefined) {
        ProductCode = "other";
      }

      var Colour = "";
      temp = this.state.Colourlist.find((el) => el.value === rowData.Colour);
      if (temp == null || temp == undefined) {
        Colour = "other";
      }

      var Weaving = "";
      temp = this.state.Weavinglist.find((el) => el.value === rowData.Weaving);
      if (temp == null || temp == undefined) {
        Weaving = "other";
      }

      var TestPerformed = "";
      temp = this.state.TestPerformedlist.find(
        (el) => el.value === rowData.TestPerformed
      );
      if (temp == null || temp == undefined) {
        TestPerformed = "other";
      }

      this.setState({
        buttonLabel: "Update",
        displaymodel: "Update Details",
        visible: true,

        // VisibleNo: authData.VisibleNo,
        displayDialog: false,
        displaymodel: true,

        SeqNo: rowData.SeqNo == null ? 0 : rowData.SeqNo,
        QRData: rowData.EncryptedValue,
        VisibleNo: rowData.SeqNo,

        ProductCode: ProductCode == "other" ? ProductCode : rowData.ProductCode,
        IProductCode: ProductCode == "other" ? rowData.ProductCode : "",
        isProductCode: ProductCode == "other" ? false : true,

        Colour: Colour == "other" ? Colour : rowData.Colour,
        IColour: Colour == "other" ? rowData.Colour : "",
        isColour: Colour == "other" ? false : true,

        Weaving: Weaving == "other" ? Weaving : rowData.Weaving,
        IWeaving: Weaving == "other" ? rowData.Weaving : "",
        isWeaving: Weaving == "other" ? false : true,

        Embroidery: Embroidery == "other" ? Embroidery : rowData.Embroidery,
        IEmbroidery: Embroidery == "other" ? rowData.Embroidery : "",
        isIEmbroidery: Embroidery == "other" ? false : true,

        UniqueCode: UniqueCode == "other" ? UniqueCode : rowData.UniqueCode,
        IUniqueCode: UniqueCode == "other" ? rowData.UniqueCode : "",
        isUniqueCode: UniqueCode == "other" ? false : true,

        TestPerformed:
          TestPerformed == "other" ? TestPerformed : rowData.TestPerformed,
        ITestPerformed: TestPerformed == "other" ? rowData.TestPerformed : "",
        isTestPerformed: TestPerformed == "other" ? false : true,

        ProductImg: rowData.ProductImg,
        Weight: rowData.Weight,
        Dimensions: rowData.Dimensions,
        BatchCode: rowData.BatchCode,
        SupplierCode: rowData.SupplierCode,
        BarCode: rowData.BarCode,
      });
    };

    return (
      <span>
        {/* <Button
          type="button"
          icon="pi pi-pencil"
          
          className="p-button"
          style={{ marginRight: "3px" }}
        ></Button> */}

        <img
          onClick={editMode}
          type="button"
          src={edit}
          width="45"
          height="45"
        />
      </span>
    );
  }

  handleDropdownColor = (event) => {
    this.handleChange(event);
    this.setState({
      Colour: event.target.value,
      isColour: event.target.value == "other" ? false : true,
    });
  };

  handleDropdowntestPerformed = (event) => {
    this.handleChange(event);
    this.setState({
      TestPerformed: event.target.value,
      isTestPerformed: event.target.value == "other" ? false : true,
    });
  };

  handleDropdownuniqueCode = (event) => {
    this.handleChange(event);
    this.setState({
      UniqueCode: event.target.value,
      isUniqueCode: event.target.value == "other" ? false : true,
    });
  };
  handleDropdownproductCode = (event) => {
    this.handleChange(event);
    this.setState({
      productCode: event.target.value,
      isProductCode: event.target.value == "other" ? false : true,
    });
  };
  handleDropdownweaving = (event) => {
    this.handleChange(event);
    this.setState({
      Weaving: event.target.value,
      isWeaving: event.target.value == "other" ? false : true,
    });
  };
  handleDropdownembroidery = (event) => {
    this.handleChange(event);
    this.setState({
      Embroidery: event.target.value,
      isIEmbroidery: event.target.value == "other" ? false : true,
    });
  };

  render() {
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );
    return (
      <>
        <Dialog
          header="QR Details"
          className="popup1 mt-1"
          closable={true}
          visible={this.state.displaymodel}
          // style={{ width: "600px" }}
          onHide={this.hidedisplaymodelFunc}
        >
          <form>
            <div className="row qr-detail pt-3 qrdetail">
              <div className="col-md-4 ">
                <label>
                  Sequence No.<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  disabled
                  type="text"
                  value={this.state.VisibleNo}
                  name="VisibleNo"
                  id="VisibleNo"
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Test Performed<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <select
                  name="TestPerformed"
                  value={this.state.TestPerformed}
                  onChange={this.handleDropdowntestPerformed}
                >
                  <option value="">Select</option>
                  {this.state.TestPerformedlist.map((obj) => {
                    return <option value={obj.value}>{obj.key}</option>;
                  })}
                </select>
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.ITestPerformed}
                  name="ITestPerformed"
                  id="TestPerformed"
                  hidden={this.state.isTestPerformed}
                />

                {this.state.formError.TestPerformed !== "" ? (
                  <div className="error">
                    {this.state.formError.TestPerformed}
                  </div>
                ) : (
                  ""
                )}
                {this.state.formError.ITestPerformed !== "" ? (
                  <div className="error">
                    {this.state.formError.ITestPerformed}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Unique Code<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <select
                  name="UniqueCode"
                  value={this.state.UniqueCode}
                  onChange={this.handleDropdownuniqueCode}
                >
                  <option value="">-Select-</option>
                  {this.state.UniqueCodelist.map((obj) => {
                    return <option value={obj.value}>{obj.key}</option>;
                  })}
                </select>

                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.IUniqueCode}
                  name="IUniqueCode"
                  id="UniqueCode"
                  hidden={this.state.isUniqueCode}
                />
                {this.state.formError.UniqueCode !== "" ? (
                  <div className="error">{this.state.formError.UniqueCode}</div>
                ) : (
                  ""
                )}
                {this.state.formError.IUniqueCode !== "" ? (
                  <div className="error">
                    {this.state.formError.IUniqueCode}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 ">
                <label>
                  Batch Code<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.BatchCode}
                  name="BatchCode"
                  id="BatchCode"
                  className="form-control"
                />
                {this.state.formError.BatchCode !== "" ? (
                  <div className="error">{this.state.formError.BatchCode}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 ">
                <label>
                  Supplier Code<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.SupplierCode}
                  name="SupplierCode"
                  id="SupplierCode"
                  className="form-control"
                />
                {this.state.formError.SupplierCode !== "" ? (
                  <div className="error">
                    {this.state.formError.SupplierCode}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 ">
                <label>
                  Product<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <select
                  name="ProductCode"
                  value={this.state.ProductCode}
                  onChange={this.handleDropdownproductCode}
                >
                  <option value="">-Select-</option>
                  {/* ramesh */}
                  {this.state.ProductCodeList.map((obj) => {
                    return <option value={obj.value}>{obj.key}</option>;
                  })}
                </select>
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.IProductCode}
                  name="IProductCode"
                  id="ProductCode"
                  hidden={this.state.isProductCode}
                />
                {this.state.formError.ProductCode !== "" ? (
                  <div className="error">
                    {this.state.formError.ProductCode}
                  </div>
                ) : (
                  ""
                )}
                {this.state.formError.IProductCode !== "" ? (
                  <div className="error">
                    {this.state.formError.IProductCode}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 ">
                <label>
                  Supplier BarCode<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.BarCode}
                  name="BarCode"
                  id="BarCode"
                  className="form-control"
                />
                {this.state.formError.BarCode !== "" ? (
                  <div className="error">{this.state.formError.BarCode}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Color(Pantone)<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <select
                  name="Colour"
                  value={this.state.Colour}
                  onChange={this.handleDropdownColor}
                  hidden
                >
                  {this.state.Colourlist.map((obj) => {
                    return <option value={obj.value}>{obj.key}</option>;
                  })}
                </select>

                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.IColour}
                  name="IColour"
                  id="Colour"
                  placeholder="Choose other colour"
                  hidden={this.state.isColour}
                />

                {this.state.formError.Colour !== "" ? (
                  <div className="error">{this.state.formError.Colour}</div>
                ) : (
                  ""
                )}
                {this.state.formError.IColour !== "" ? (
                  <div className="error">{this.state.formError.IColour}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Weaving<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <select
                  name="Weaving"
                  value={this.state.Weaving}
                  onChange={this.handleDropdownweaving}
                >
                  <option value="">-Select-</option>
                  {this.state.Weavinglist.map((obj) => {
                    return <option value={obj.value}>{obj.key}</option>;
                  })}
                </select>
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.IWeaving}
                  name="IWeaving"
                  id="Weaving"
                  hidden={this.state.isWeaving}
                />
                {this.state.formError.Weaving !== "" ? (
                  <div className="error">{this.state.formError.Weaving}</div>
                ) : (
                  ""
                )}
                {this.state.formError.IWeaving !== "" ? (
                  <div className="error">{this.state.formError.IWeaving}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Embroidery<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <select
                  name="Embroidery"
                  value={this.state.Embroidery}
                  onChange={this.handleDropdownembroidery}
                >
                  <option value="">-Select-</option>
                  {this.state.Embroideries.map((obj) => {
                    return <option value={obj.value}>{obj.key}</option>;
                  })}
                </select>
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.IEmbroidery}
                  name="IEmbroidery"
                  id="Embroidery"
                  hidden={this.state.isIEmbroidery}
                />
                {/* ramesh */}

                {this.state.formError.Embroidery !== "" ? (
                  <div className="error">{this.state.formError.Embroidery}</div>
                ) : (
                  ""
                )}
                {this.state.formError.IEmbroidery !== "" ? (
                  <div className="error">
                    {this.state.formError.IEmbroidery}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 ">
                <label>
                  Weight (Gram(s))<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.Weight}
                  name="Weight"
                  id="Weight"
                  className="form-control"
                />
                {this.state.formError.Weight !== "" ? (
                  <div className="error">{this.state.formError.Weight}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Dimensions (cm(s))<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.Dimensions}
                  name="Dimensions"
                  id="Dimensions"
                  className="form-control"
                />
                {this.state.formError.Dimensions !== "" ? (
                  <div className="error">{this.state.formError.Dimensions}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4">
                <label>
                  Product Image<span className="error">*</span> &nbsp;:
                </label>
              </div>
              <div className="col-md-8">
                <FileUpload
                  name="ProductImg"
                  url={process.env.REACT_APP_API_URL + "ScanQR/ImageUpload"}
                  auto={true}
                  onUpload={this.setImagePath}
                  onError={this.onErrorImage}
                  accept="image/*"
                />
                {this.state.ProductImg ? (
                  <div className="p-fileupload-row mt-1 product-imge">
                    <div>
                      <img
                        src={this.state.ProductImg}
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                ) : null}
                {this.state.formError.ProductImg !== "" ? (
                  <div className="error">{this.state.formError.ProductImg}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4">
                <label>
                  Fake Verify<span></span> &nbsp;:
                </label>
              </div>
              <div className="col-md-4 text-left">
                <input
                  type="checkbox"
                  onChange={(event) =>
                    this.setState({ fakeVerify: event.target.checked })
                  }
                  checked={this.state.fakeVerify}
                  name="fakeVerify"
                  className="ml-2 mt-3"
                />
              </div>

              <div className="col-md-4 text-center">
                <div className="modal-footer d-flex justify-content-center mt-2">
                  <button
                    onClick={this.submitQRDetails}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Dialog>

        <div className="container-flud mt5">
          <form onSubmit={this.submitForm}>
            <div className="card">
              <div className="card-header">
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-md-6 scane">
                    <strong>Please Scan/Enter QR Code</strong>
                  </div>
                  <div className="col-md-6 counte">
                    <div className="widget-subheading">
                      Total Count of Mapped QR Codes :{" "}
                      <span className="mapped-count">
                        {" "}
                        {this.state.noOfCount ? this.state.noOfCount : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row justify-content-center ">
                  <div className="col-12 qr-box qr-boxx">
                    <div
                      className="input-group mb-3"
                      style={{ display: "flex-col" }}
                    >
                      <label>
                        QR Code<span className="error">*</span> &nbsp;:
                      </label>
                      <input
                        onChange={(e) =>
                          this.setState({ QRData: e.target.value })
                        }
                        value={this.state.QRData}
                        id="QRData"
                        name="QRData"
                        type="text"
                        variant="filled"
                        className="form-control"
                        placeholder="Scan QR Code*"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn center-block btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        Get Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="card" style={{ marginTop: "5px" }}>
            <DataTable
              responsive={true}
              style={{ width: "100%" }}
              scrollable
              value={this.state.epchData}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              onsort={this.onSort}
            >
              <Column field="SeqNo" header="Seq No" sortable={true} />
              <Column field="UniqueCode" header="Unique Code" sortable={true} />
              <Column field="BatchCode" header="Batch Code" sortable={true} />
              <Column
                field="SupplierCode"
                header="Supplier Code"
                sortable={true}
              />
              <Column field="ProductCode" header="Product" sortable={true} />
              <Column
                field="BarCode"
                header="SupplierBarCode"
                sortable={true}
              />

              <Column field="Colour" header="Colour" sortable={true} />
              <Column field="Weaving" header="Weaving" sortable={true} />
              <Column field="Embroidery" header="Embroidery" sortable={true} />

              <Column field="Weight" header="Weight" sortable={true} />
              <Column field="Dimensions" header="Dimensions" sortable={true} />
              <Column field="TestPerformed" header="Tests Performed" />

              <Column
                field="ProductImg"
                style={{ width: "100px" }}
                header="Product Image"
                body={this.brandTemplate}
              />
              {this.state.userEmail == "WII@gmail.com" ||
              this.state.userEmail == "skg@wii.gov.in" ||
              this.state.userEmail == "cpsharma@wii.gov.in" ? (
                <Column
                  body={this.actionTemplate}
                  headerStyle={{ width: "100px" }}
                  header="Action"
                />
              ) : null}
            </DataTable>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}
