import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { CSVLink, CSVDownload } from "react-csv";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import tableRequest from "../../models/tableRequest";
import DemappingReportService from "../../services/DemappingReportService.js";
// import MessageComponent from '../../template/Message';
import $ from "jquery";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";

export default class DamageReport extends Component {
  constructor(props) {
    super(props);
    this.surveyLink = React.createRef();

    this.DemappingReportService = new DemappingReportService();
    this.tblResponse = new tableRequest();
    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      success: false,
      visible: false,
      sortOrder: "",
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      updateLoader: false,
      emptyMessage: "No Data found",
      allData: [],
    };
    this.onPage = this.onPage.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.GetDamageExport = this.GetDamageExport.bind(this);
    this.GetDamageData = this.GetDamageData.bind(this);
    this.onSort = this.onSort.bind(this);
    //this.setPreprintedValue = this.setPreprintedValue.bind(this);
  }
  //to trigger api function on page call

  componentDidMount() {
    setTimeout(() => {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      this.tblResponse.FromDate = date.toLocaleDateString();
      this.tblResponse.ToDate = new Date().toLocaleDateString();
      this.setState({
        FromDate: date,
        ToDate: new Date(),
      });
      this.GetDamageData();
      //this.GetDemappingExport();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  //to search
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetDamageData();
  }

  onChange = (date) => this.setState({ FromDate: "", ToDate: "" });
  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetDamageData();
    }, 250);
  }

  // get data list from api
  GetDamageExport() {
    this.DemappingReportService.GetDamageReport(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          resdata.ResponseData.forEach(function (v) {
            delete v.TotalRows;
            delete v.IsDemapped;
            delete v.ProductImg;
            delete v.Weight;
            delete v.Dimensions;
            delete v.BatchCode;
            delete v.fakeVerify;
            delete v.SupplierCode;
            delete v.FromDate;
            delete v.ToDate;
          });
          this.setState({
            allData: resdata.ResponseData,
          });
          this.setState({ allData: resdata.ResponseData }, () => {
            this.surveyLink.link.click();
          });

          // if (resdata.ResponseData !== null) {

          // }
        }
      }
    );
  }
  //to handle
  //resolved KREP-22
  handleChange(event) {
    const { name, value } = event.target;
    //to validate each form field with required conditions
    switch (name) {
      case "FromDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "From date must be less than to date."
            );
            return;
          }
        }

        break;
      case "ToDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "To date must be greater than from date."
            );
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }
  //to filter data
  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    this.GetDamageData();
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  // get data list from api

  GetDamageData() {
    debugger;
    this.tblResponse.SortColumn = "CreatedDate";
    this.DemappingReportService.GetDamageReport(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        this.setState({ updateLoader: true });
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        //if response data null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
      }
    );
    // this.datasource = null;
    // this.setState({
    //     totalRecords: 0,
    //     first: 1,
    //     sales: null,
    //     loading: false
    // });
  }
  //to sort
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDate" ? "CreatedDate" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetDamageData();
  }

  /// to set isPreprinted
  // setPreprintedValue(e) {

  //     this.setState({
  //         isPreprintedValue: e.value,
  //         isPreprinted: e.value == "Static Qr Demapping" ? false : true
  //     }, () => {
  //         this.tblResponse.isPreprinted = this.state.isPreprinted;
  //         this.GetDemapping();
  //     });

  // }

  //to render html part
  render() {
    const items = [{ label: "Masters" }, { label: "Damage Report" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };
    const options = ["Static Qr Damage"]; //, 'PrePrinted Qr Damage'];
    let header = (
      <div className="row">
        <div className="col-md-8">
          <form className="form-inline">
            <div className="form-group mr-2">
              <Calendar
                value={this.state.FromDate}
                name="FromDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="From Date"
              />
            </div>
            <div className="form-group">
              <Calendar
                value={this.state.ToDate}
                name="ToDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="To Date"
              />
            </div>
            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary ml-3"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="text-right col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="22"
          />
          <button
            className="btn btn-primary ml-2 mb-1 export-disabled"
            target="_blank"
            onClick={this.GetDamageExport}
          >
            Export
          </button>
          <CSVLink
            hidden={true}
            data={this.state.allData}
            ref={(r) => (this.surveyLink = r)}
            filename={"Damage report.csv"}
          >
            Export
          </CSVLink>
        </div>
      </div>
    );
    return (
      <>
        {/* <MessageComponent ref="Messchild" /> */}
        {/* {<Loaders loader={this.state.updateLoader} /> } */}

        <Card className="mt5">
          <div className="row">
            <div className="col-md-6 heading report">
              <h3>Damage Report</h3>
            </div>
            <div className="col-md-6 custom-breadcrumb report">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <DataTable
            exportFilename="Damage Report"
            scrollable
            responsive={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="SeqNo" header="Seq No" sortable={true} />
            <Column field="ProductCode" header="Product" sortable={true} />
            <Column field="SupplierCode" header="Supplier" sortable={true} />
            <Column field="BarCode" header="BarCode" sortable={true} />
            <Column field="UniqueCode" header="Unique Code" sortable={true} />
            <Column field="Colour" header="Colour" sortable={true} />
            <Column field="CreatedDate" header="Damage Date" sortable={true} />
          </DataTable>
        </Card>
      </>
    );
  }
}
