import axios from "axios";

export default class DemappingReportService {
  ControllerName = "User";

  // get all data
  GetDemappingReport(tableRequest) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDemappingReport`,
        tableRequest
      )
      .then((res) => res);
  }

  // get all data
  GetDamageReport(tableRequest) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDamageReport`,
        tableRequest
      )
      .then((res) => res);
  }

  // Get Excel  Export Mapping data
  GetDemappingReportEX(tableRequest) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDemappingReportEX`,
        tableRequest
      )
      .then((res) => res);
  }

  // get all data Mapping Test
  GetMappingReportTest(tableRequestMappingTest) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappingReportTest`,
        tableRequestMappingTest
      )
      .then((res) => res);
  }
}
