import React, { Component } from 'react';
// import { Growl } from 'primereact/growl';
import { Toast } from 'primereact/toast';

// To show Message when call api
export class MessageComponent extends Component {
    constructor() {
        super();

        this.showSuccess = this.showSuccess.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showError = this.showError.bind(this);
        this.clear = this.clear.bind(this);
    }

    // Show Success
    showSuccess(msg) {
        // this.growl.show({ severity: 'success', detail: msg });
        this.toast.show({severity:'success', summary: 'Success Message', detail:msg, life: 3000});
        return true;
    }

    // Show Info
    showInfo(msg) {
        this.toast.show({severity:'info', summary: 'Info Message', detail:msg, life: 3000});
        // this.growl.show({ severity: 'info', detail: msg });
    }

    // Show Warn
    showWarn(msg) {
        this.toast.show({severity:'warn', summary: 'Warn Message', detail:msg, life: 3000});
        // this.growl.show({ severity: 'warn', detail: msg});
    }

    // Show Error
    showError(msg) {
        this.toast.show({severity:'error', summary: 'Error Message', detail:msg, life: 3000});
        // this.growl.show({ severity: 'error', detail: msg});
    }

    // Show Clear
    clear() {
        this.toast.clear();
        // this.growl.clear();
    }

// html message
    render() {
        return (
            <div>
                <div className="content-section implementation p-fluid">
                    {/* <Growl ref={(el) => this.growl = el} /> */}
                    <Toast ref={(el) => this.toast = el} />
                </div>
            </div>
        )
    }
}

export default MessageComponent