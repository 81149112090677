import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { InputText } from "primereact/inputtext";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Dropdown } from "primereact/dropdown";
import AccessRightService from "../../services/AccessRightService";
import UserService from "../../services/UserService";
import MessageComponent from "../../template/Message";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { data } from "jquery";

// Manage Access Right for User
export default class AccessRight extends Component {
  constructor(props) {
    super(props);
    this.AccessRightService = new AccessRightService();
    this.UserService = new UserService();
    this.state = {
      visible: false,
      nodes: [],
      nodeToggle: false,
      globalFilter: null,
      UserId: 0,

      masters: null,
      trans: null,
      report: null,
      Divs: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      formError: {
        UserId: null,
      },
      userddlList: [],
    };

    // this.pagesTemplate = this.pagesTemplate.bind(this);
    // this.treeTemplate = this.treeTemplate.bind(this);
    // this.actionTemplate = this.actionTemplate.bind(this);
    this.checkBox = this.checkBox.bind(this);
    this.SubmitData = this.SubmitData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.checkCheckbox = this.checkCheckbox.bind(this);
    //this.checkboxHandler = this.checkboxHandler(this);
  }

  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      //this.GetAccessRight();
      this.GetUserList();
    }, 100);
  }

  // get User List
  GetUserList() {
    this.UserService.GetUsersDDL().then((data) => {
      const resdata = data.data;
      //if resdata not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          userddlList: resdata.ResponseData,
        });
      }
    });
  }

  // get Access Right
  GetAccessRight() {
    // const userId = parseInt(e.target.value);

    this.AccessRightService.GetAccessRight(0).then((data) => {
      this.setState({
        nodes: data.data.ResponseData,
      });
    });
  }

  checkBox(rowData) {
    return (
      <div>
        <input disabled checked={rowData.Status} type="checkbox" />
      </div>
    );
  }

  Expand(e) {
    const key = e.node.key;
    var checkbox = document.getElementById(key);
    var IsChecked = checkbox.checked;
    var checkboxlist = document.getElementsByClassName(key);
    for (var checkbox of checkboxlist) {
      if (IsChecked) {
        //checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    }
  }

  Collapse(e) {
    const key = e.node.key;
    var checkbox = document.getElementById(key);
    var IsChecked = checkbox.checked;
    var checkboxlist = document.getElementsByClassName(key);
    for (var checkbox of checkboxlist) {
      if (IsChecked) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    }
  }

  onCheckAll(e) {
    //console.log(e);
    var chkValue = e.currentTarget.value;
    var className = e.currentTarget.className;
    var IsChecked = e.currentTarget.checked;
    //if className is not ""
    if (className !== "") {
      var checkboxlist = document.getElementsByClassName("child" + className);
      for (var checkbox of checkboxlist) {
        checkbox.checked = IsChecked;
      }
    }
  }

  childUncheck(e) {
    //console.log(e);
    var chkValue = e.currentTarget.value;
    var className = e.currentTarget.className;
    var IsChecked = e.currentTarget.checked;
    let selectAllClassName = className.replace("child", "");
    //if className is not ""
    if (className !== "" && !IsChecked) {
      var selectAllCheck = document.getElementsByClassName(selectAllClassName);
      selectAllCheck[0].checked = false;
    }
  }

  // call every change of form and validation part also

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    this.formIsValid = true;

    //to validate each form field with required conditions

    switch (name) {
      case "UserId":
        if (value == "") {
          this.formIsValid = false;
          errors.UserId = "Please select user.";
        } else errors.UserId = "";
        break;
    }

    this.setState({ errors, [name]: value });
  }
  onUserChange(e) {
    const userId = parseInt(e.target.value);
    //const userId = parseInt(e.currentTarget.value);
    this.AccessRightService.GetAccessRight(userId).then((data) => {
      this.setState({
        nodes: data.data.ResponseData,
      });
      this.editcheckbox();
    });

    this.handleChange(e);
  }

  // to submit data
  SubmitData() {
    const checkboxes = document.getElementsByName("childCheck");
    let checkedValuelst = [];
    checkedValuelst.push("1");
    for (var checkbox of checkboxes) {
      if (checkbox.checked) {
        checkedValuelst.push(checkbox.value);
      }
    }

    const parentlist = this.state.nodes;
    parentlist.map((parent) => {
      let parentId = "";
      const childlst = parent.children;
      childlst.map((child) => {
        const key = child.key;
        if (checkedValuelst.indexOf(key) >= 0) {
          parentId = parent.key;
        }
      });
      if (parentId != "") {
        checkedValuelst.push(parentId);
      }
    });

    const formData = {
      UserId: this.state.UserId,
      PageList: checkedValuelst,
    };

    if (formData.UserId > 0) {
      this.AccessRightService.CreateUpdateAccessRight(formData).then((data) => {
        const resdata = data.data;
        //if ResponseCode is 200 success else error or warning message
        if (resdata !== null && resdata.ResponseCode === "200") {
          this.refs.Messchild.showSuccess(resdata.ResponseMessage);
        } else {
          this.refs.Messchild.showError(resdata.ResponseMessage);
        }
      });
    } else {
      let errors = this.state.formError;
      errors.UserId = "Please select user";
      // this.setState({ formError: erlist });
      this.setState({ errors: "" });
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  //to edit data
  editcheckbox() {
    const listdata = this.state.nodes;
    const checkboxes = document.getElementsByName("childCheck");
    for (var checkbox of checkboxes) {
      let ischeck = false;
      listdata.map((node, i) => {
        node.children.map((childNode, k) => {
          if (checkbox.value == childNode.key && childNode.Status == true) {
            ischeck = true;
          }
        });
      });

      if (ischeck) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    }
  }

  //to render html data
  render() {
    const items = [{ label: "Masters" }, { label: "Access Right" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    const TreeNode = this.state.nodes;

    const TreeStruture = TreeNode.map((node, i) => (
      <div>
        <Accordion>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ fontSize: "1vw" }}>
              {node.data.pages}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="tree-stucture"></ul>
            <ul className="tree-stucture">
              <li>
                <input
                  type="checkbox"
                  className={i}
                  onChange={this.onCheckAll.bind(this)}
                />{" "}
                Select all
              </li>
              {node.children.map((childNode, k) =>
                childNode.data.pages == "Issue Number" ? (
                  ""
                ) : (
                  <li key={childNode.key}>
                    <input
                      id={"Id" + k}
                      name="childCheck"
                      defaultChecked={this.props.complete}
                      value={childNode.key}
                      type="checkbox"
                      onChange={this.childUncheck.bind(this)}
                      className={"child" + i}
                    />
                    {childNode.data.pages}
                  </li>
                )
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
        <br />
      </div>
    ));

    const RoleName = this.state.userddlList;
    const userOption = RoleName.map((roleName) => (
      <option value={roleName.value}>{roleName.label}</option>
    ));

    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Access Right</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <div>
            <h3 className="mt-4 mb-4">Select User</h3>
            <form>
              <Dropdown
                filter
                style={{ width: "300px" }}
                options={this.state.userddlList}
                placeholder="Select User"
                className="form-control mb-4"
                value={this.state.UserId}
                onChange={this.onUserChange.bind(this)}
                name="UserId"
              />
              {this.state.formError.UserId !== "" ? (
                <div className="error">{this.state.formError.UserId}</div>
              ) : (
                ""
              )}
            </form>
          </div>
        </Card>
        <span hidden={TreeStruture.length > 0 ? false : true}>
          <div className="border-box">
            <h3 className="mt-3 mb-4">Select Pages</h3>
            {TreeStruture}
          </div>
          <div className="text-center mt-2 pb-3">
            <button
              type="button"
              onClick={this.SubmitData}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </span>

        {/* <MessageComponent ref="Messchild" />
        <Card className="mt5">
          <div className="row">
            <div className="col-md-6 heading master">
              <h3>Access Right</h3>
            </div>
            <div className="col-md-6 custom-breadcrumb master">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <hr></hr>
          <div className="access-right">
            <h4 className="">Select Pages</h4>
            <div className="container mt-3 access-right">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingTwo"
                  >
                    <button
                      className="dashboard-data collapsed"
                      ttype="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Dashboard
                    </button>
                  </h2>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree1"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Masters
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree1"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div className="accordion-body ms-3">
                      <div className="access-right1">
                        <input type="checkbox" className="selectall" /> Select All <br /> 
                        <input type="checkbox" className="individual" /> User
                        Master <br />
                        <input type="checkbox" className="individual" /> Access
                        Right <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree2"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Transactions
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree2"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div className="accordion-body ms-3">
                      <div className="access-right1">
                        <input type="checkbox" className="selectall" /> Select All <br /> 
                        <input type="checkbox" className="individual" /> Mapping{" "}
                        <br />
                        <input type="checkbox" className="individual" /> De
                        Mapping <br />
                        <input type="checkbox" className="individual" /> Damage
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree3"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Reports
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree3"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div className="accordion-body ms-3">
                      <div className="access-right1">
                        {/* <input type="checkbox" className="selectall" /> Select All <br /> 
                        <input type="checkbox" className="individual" />{" "}
                        Demapping Report
                        <br />
                        <input type="checkbox" className="individual" />
                        Mapping Test <br />
                        <input type="checkbox" className="individual" />{" "}
                        Download Certificate
                        <br />
                        <input type="checkbox" className="individual" /> Damage
                        Report
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card> */}
      </>
    );
  }
}
