import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { CSVLink, CSVDownload } from "react-csv";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import tableRequest from "../../models/tableRequest";
import DemappingReportService from "../../services/DemappingReportService.js";
import DownloadReportService from "../../services/DownloadReportService.js";
import PAService from "../../../src/services/PAService";
import VerifyProduct from "../../models/VerifyProduct";
// import MessageComponent from '../../template/Message';
import $ from "jquery";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";

import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast, ToastContainer } from "react-toastify";
import productimgpdf from "../../assets/images/PashminaCertificate.pdf";
import productimgUpdatedpdf from "../../assets/images/PashminaUpdatedCertificate.pdf";
import productimg2pdf from "../../assets/images/certificateAdultration.pdf";
import certificateAdultrationUpdated from "../../assets/images/certificateAdultrationUpdated.pdf";
import ".././Pa.css";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import QRCode from "qrcode";
import dowimg from "../../assets/images/dowimg.png";
import dowimgall from "../../assets/images/doll.png";
import image from "../../assets/638264776958624820.jpg";

export default class MappingTestReport extends Component {
  constructor(props) {
    super(props);
    this.surveyLink = React.createRef();

    this.DemappingReportService = new DemappingReportService();
    this.DownloadReportService = new DownloadReportService();
    this.PAService = new PAService();

    this.tblResponse = new tableRequest();
    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      success: false,
      visible: false,
      sortOrder: "",
      sales: [],
      AllgetData: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      updateLoader: false,
      emptyMessage: "No Data found",
      allData: [],
      uid: "",

      QrData: window.location.href,
      SeqNo: "",
      demapping: [],
      TestPerformed: "",
      UniqueCode: "",
      Weaving: "",
      IsActive: true,
      fakeVerify: true,
      ProductImg: "",
      IsDamaged: true,
      IsDemapped: true,
      ProductCode: "",
      BarCode: "",
      ProductAuthenticationData: null,
      Colour: "",
      Embroidery: "",
      Weight: "",
      Dimensions: "",
      BatchCode: "",
      SupplierCode: "",
    };
    this.onPage = this.onPage.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.GetDownloadExport = this.GetDownloadExport.bind(this);
    this.GetMappingReportTest = this.GetMappingReportTest.bind(this);

    this.onSort = this.onSort.bind(this);

    this.printpdfDATA = this.PrintpdfDATA.bind(this);
    this.PrintpdfDATAallPdf = this.PrintpdfDATAallPdf.bind(this);

    this.downloadUpdate = this.downloadUpdate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);

    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
  }
  //to trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      this.tblResponse.FromDate = date.toLocaleDateString();
      this.tblResponse.ToDate = new Date().toLocaleDateString();
      this.setState({
        FromDate: date,
        ToDate: new Date(),
      });
      this.GetDownload();
      //this.GetDemappingExport();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Userlatitude,
      this.clsVerifyProduct.Userlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.City = res.address?.state_district;
        this.clsVerifyProduct.State = res.address?.state;
        this.clsVerifyProduct.Country = res.address?.country;
        this.clsVerifyProduct.Pincode = res.address?.postcode;
        this.clsVerifyProduct.Address = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }
  //to search
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetDownload();
  }

  // get verify product list data
  async GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });
    const authData = await this.PAService.VerifyProduct(productAuthReq);
    //console.log("auth adulteration data", authData);
    this.setState({
      ProductAuthenticationData: authData.data.ResponseData,
    });
  }

  onChange = (date) => this.setState({ FromDate: "", ToDate: "" });
  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetDownload();
    }, 250);
  }

  // get data list from api
  GetMappingReportTest() {
    this.DemappingReportService.GetMappingReportTest(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          resdata.ResponseData.forEach(function (v) {
            delete v.TotalRows;
            delete v.IsDemapped;
            delete v.ProductImg;
            delete v.FromDate;
            delete v.ToDate;
            delete v.ProductImgBase64;
            delete v.UID;
          });
          this.setState({
            allData: resdata.ResponseData,
          });
          this.setState({ allData: resdata.ResponseData }, () => {
            this.surveyLink.link.click();
          });

          // if (resdata.ResponseData !== null) {

          // }
        }
      }
    );
  }

  //to handle
  //resolved KREP-22
  handleChange(event) {
    debugger;
    const { name, value } = event.target;
    //to validate each form field with required conditions
    switch (name) {
      case "FromDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "From date must be less than to date."
            );
            return;
          }
        }

        break;
      case "ToDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "To date must be greater than from date."
            );
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }
  //to filter data
  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    this.GetDownload();
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    // this.tblResponse.FromDate = this.state.FromDate;
    // this.tblResponse.ToDate = this.state.ToDate;
  }

  // get data list from api

  GetDownload() {
    this.tblResponse.SortColumn = "CreatedDate";
    this.DemappingReportService.GetMappingReportTest(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        this.setState({ updateLoader: true });
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        //if response data null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
      }
    );
  }
  //to sort
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetDownload();
  }

  //
  // edit form

  downloadUpdate(data) {
    const formData = {
      SeqNo: data,
    };
    this.DownloadReportService.CreateDownloadCount(formData).then((data) => {
      const resdata = data.data;

      if (resdata.ResponseMessage == "success") {
        // toast.success("success");
      } else {
      }
    });
  }

  getDate(d) {
    debugger;
    var date = d.split("/");
    var f = new Date(date[2], (parseInt(date[1]) - 1).toString(), date[0]);
    return f;
  }

  getBase64FromUrl = async (url) => {
    debugger;
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  PrintpdfDATA = async (rowData) => {
    debugger;
    let QRDATA = window.location.origin + "/pa?uid=" + rowData.UID;
    let qrdata = this.genQR(QRDATA);
    var imageURL = rowData.ProductImg;
    //var imageURL = image;
    rowData.ProductImgBase64 = await this.getBase64FromUrl(imageURL);
    debugger;
    if (rowData.fakeVerify == "True") {
      if (this.getDate(rowData.CreatedDate) >= new Date("08/27/2023")) {
        this.printUpdatedFakeDocument(rowData, qrdata);
      } else {
        this.printDocumentfakeVerify(rowData, qrdata);
      }
    } else {
      if (this.getDate(rowData.CreatedDate) >= new Date("08/27/2023")) {
        this.printUpdatedDocument(rowData, qrdata);
      } else {
        this.printDocument(rowData, qrdata);
      }
    }
    // if (rowData.fakeVerify == "True") {
    //   this.printDocumentfakeVerify(rowData, qrdata);
    // } else if (
    //   rowData.CreatedDate >= "25/08/2023" &&
    //   rowData.fakeVerify != "True"
    // ) {
    //   this.printUpdatedDocument(rowData, qrdata);
    // } else {
    //   this.printDocument(rowData, qrdata);
    // }
  };

  PrintpdfDATAallPdf() {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to Download All.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.DemappingReportService.GetMappingReportTest(
              this.tblResponse
            ).then((data) => {
              debugger;
              const resdata = data.data;

              if (
                resdata !== null &&
                resdata.ResponseData !== null &&
                resdata.ResponseData.length > 0
              ) {
                debugger;
                let data = resdata.ResponseData;
                data.forEach((obj) => {
                  this.PrintpdfDATA(obj);
                });
              }
            }),
        },
        {
          label: "No",
        },
      ],
    });
  }

  // edit form
  actionTemplate(rowData) {
    const submit = () => {
      confirmAlert({
        title: "Confirmation",
        message: "Are you sure you want to Download.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.PrintpdfDATA(rowData),
          },
          {
            label: "No",
          },
        ],
      });
    };

    return (
      <span className="dowimg">
        {/* <Button
          type="button"
          label="Download"
          onClick={submit}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button> */}
        <img
          onClick={submit}
          type="button"
          src={dowimg}
          width="45"
          height="47"
        />
      </span>
    );
  }

  //Label print starts
  async printDocument(rowData, qrdata) {
    const url = productimgpdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );
    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);
    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 125,
      y: 319.3,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 138,
        y: 211.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 87,
        y: 292.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 98,
        y: 265,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 114,
        y: 238.5,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 688,
        y: 347,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 154,
      y: 350,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 642,
        y: 211.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 815,
        y: 211.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 807,
        y: 118,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 750,
          y: 250,
          width: jpgDims.width,
          height: jpgDims.height,
          size: 11,
          font: helveticaFont,
          size: 15,
          color: rgb(0, 0, 0),
        }
      );
    }
    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    // link.setAttribute("download", "PashminaCertificate.pdf");
    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");
    document.body.appendChild(link);
    link.click();
  }

  //Label Updated print starts
  async printUpdatedDocument(rowData, qrdata) {
    debugger;
    const url = productimgUpdatedpdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );

    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);

    const jpgImage2 = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDimsProdImg = jpgImage2.scale(1.13);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 125,
      y: 316.5,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 152,
        y: 218.3,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 175,
        y: 285,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 98,
        y: 253.1,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 473,
        y: 253.3,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 482,
        y: 350,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 154,
      y: 350,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 448,
        y: 316.5,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 467,
        y: 285.1,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 815,
        y: 118,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 690,
          y: 202,
          width: jpgDimsProdImg.width,
          height: jpgDimsProdImg.height,
          size: 45,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
      );
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");

    document.body.appendChild(link);
    link.click();
  }

  async printUpdatedFakeDocument(rowData, qrdata) {
    debugger;
    const url = certificateAdultrationUpdated;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );

    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);

    const jpgImage2 = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDimsProdImg = jpgImage2.scale(1.2);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 125.5,
      y: 337.6,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 152,
        y: 224.4,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 175,
        y: 301.1,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 97,
        y: 264,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 493,
        y: 263.9,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 502,
        y: 372.9,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 154.5,
      y: 373,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 468,
        y: 337.8,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 487,
        y: 301.4,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 825,
        y: 120,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 690,
          y: 214,
          width: jpgDimsProdImg.width,
          height: jpgDimsProdImg.height,
          size: 45,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
      );
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    // link.setAttribute("download", "PashminaCertificate.pdf");
    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");

    document.body.appendChild(link);
    link.click();
  }

  async printDocumentfakeVerify(rowData, qrdata) {
    const url = productimg2pdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );

    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);
    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 126,
      y: 334,
      size: 11,
      font: helveticaFont,
      size: 13,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 140,
        y: 225,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 89,
        y: 307,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 100,
        y: 279,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 115,
        y: 252,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 690,
        y: 362.5,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 155.5,
      y: 365,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 642,
        y: 225,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 816,
        y: 225,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 800,
        y: 118,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 750,
          y: 265,
          width: jpgDims.width,
          height: jpgDims.height,
          size: 11,
          font: helveticaFont,
          size: 13,
          color: rgb(0, 0, 0),
        }
      );
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    // link.setAttribute("download", "PashminaAdulterationCertificate.pdf");

    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");

    document.body.appendChild(link);
    link.click();
  }

  genQR(text) {
    let qr = "";
    QRCode.toDataURL(text, function (err, url) {
      qr = url;
    });
    return qr;
  }

  //to render html part
  render() {
    const items = [{ label: "Masters" }, { label: "Mapping-Test Report" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };
    const options = ["Static Qr Download"]; //, 'PrePrinted Qr Demapping'];
    let header = (
      <div className="row">
        <div className="col-md-8">
          <form className="form-inline">
            <div className="form-group mr-2">
              <Calendar
                value={this.state.FromDate}
                name="FromDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="From Date"
              />
            </div>
            <div className="form-group">
              <Calendar
                value={this.state.ToDate}
                name="ToDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="To Date"
              />
            </div>
            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary ml-3"
            >
              Submit
            </button>
            <div className="form-group mr-3">
              {" "}
              <span> </span>
            </div>
            <span>
              <img
                onClick={this.PrintpdfDATAallPdf}
                src={dowimgall}
                width={140}
                height={45}
              />
            </span>
          </form>
        </div>
        <div className="text-right col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="22"
          />
          <button
            className="btn btn-primary ml-2 mb-1 export-disabled"
            target="_blank"
            onClick={this.GetMappingReportTest}
          >
            Export
          </button>

          <CSVLink
            hidden={true}
            data={this.state.allData}
            ref={(r) => (this.surveyLink = r)}
            filename={"MappingTest report.csv"}
          >
            Export
          </CSVLink>
        </div>
      </div>
    );
    return (
      <>
        {/* <MessageComponent ref="Messchild" /> */}
        {/* {<Loaders loader={this.state.updateLoader} /> } */}

        <Card className="mt5">
          <div className="row">
            <div className="col-md-6 heading report">
              <h3>Mapping-Test Report </h3>
            </div>
            <div className="col-md-6 custom-breadcrumb report">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <DataTable
            exportFilename="GetMappingTest Report"
            scrollable
            responsive={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="SeqNo" header="Seq No" sortable={true} />
            <Column field="ProductCode" header="Product" sortable={true} />
            <Column field="BarCode" header="Bar Code" sortable={true} />
            <Column field="UniqueCode" header="Unique Code" sortable={true} />
            <Column field="BatchCode" header="Batch Code" sortable={true} />
            <Column
              field="SupplierCode"
              header="Supplier Code"
              sortable={true}
            />
            <Column field="Colour" header="Colour" sortable={true} />
            <Column field="Weaving" header="Weaving" sortable={true} />
            <Column field="Embroidery" header="Embroidery" sortable={true} />

            <Column field="Weight" header="Weight" sortable={true} />
            <Column field="Dimensions" header="Dimensions" sortable={true} />
            <Column
              field="TestPerformed"
              header="Tests Performed"
              sortable={true}
            />
            <Column body={this.actionTemplate} header="Certificate Download" />
          </DataTable>
        </Card>
      </>
    );
  }
}
