import axios from 'axios';

export default class AccessRightService {
  ControllerName = 'AccessRight';

   // get menu according user
   GetAccessRight(userId) {
    ;
      return axios.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetAccessRight/${userId}`).then(res => res)
  }
   // get create and update access right
   CreateUpdateAccessRight(accessdata) {
      return axios.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateAccessRight`,accessdata).then(res => res)
  }     
} 