import React from "react";
import "./../App.css";

//Return Footer HTML
const Footer = () => {
  return (
    <div className="row footer">
      <div className="col-md-6 text-white text-left">
        Copyright © 2023 Wildlife Institution of India - All Rights Reserved.
      </div>
      <div className="col-md-6 text-right">Design & Developed by <a  target="_blank" href="https://holostik.com/" className="desinged">Holostik India Limited</a></div>
   </div>
  );
};

export default Footer;
