import axios from "axios";

export default class ScanQRService {
  ControllerName = "ScanQR";

  // create new user
  ScanQRCode(rndmNo) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/ScanQRCode?rndmNo=${rndmNo}`
      )
      .then((res) => res);
  }

  // forgot password
  CreatetvpScannedQR(logindata) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreatetvpScannedQR`,
        logindata
      )
      .then((res) => res);
  }

  GetEPCHData(tableRequest) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetScanQRData`,
        tableRequest
      )
      .then((res) => res);
  }

  Damage(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateDamage`,
        data
      )
      .then((res) => res);
  }

  GetDashBoardData(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDashboardData`,
        data
      )
      .then((res) => res);
  }
}
