import axios from 'axios';

export default class DownloadReportService {
    ControllerName = 'ScanQR';

    // get all data
    GetDownloadReport(tableRequest) {
            return axios.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDownloadReport`, tableRequest).then(res => res)
        }
        
        CreateDownloadCount(data) {
          ;
            return axios
              .post(
                `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateDownloadCount`,
                data
              )
              .then((res) => res);
          }
}