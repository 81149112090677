import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Logo from "./../assets/images/epch-logo.png";
import verified from "./../assets/images/verified.png";
import Fake from "./../assets/images/Fake.png";
import valid from "./../assets/images/valid.gif";
import invalid from "./../assets/images/invalid.gif";
import VerifyProduct from "../models/VerifyProduct";
import PAService from "../../src/services/PAService";
import DownloadReportService from "../services/DownloadReportService.js";
import productimgpdf from "../assets/images/PashminaCertificate.pdf";
import productimgUpdatedpdf from "../assets/images/PashminaUpdatedCertificate.pdf";
import productimg2pdf from "../assets/images/certificateAdultration.pdf";
import certificateAdultrationUpdated from "../assets/images/certificateAdultrationUpdated.pdf";
import "./Pa.css";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import QRCode from "qrcode";

export default class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.PAService = new PAService();
    this.DownloadReportService = new DownloadReportService();
    this.clsVerifyProduct = new VerifyProduct();

    this.state = {
      buttonlabel: "Submit",
      formIsValid: false,
      IsLocation: false,
      //ratingBox: false,
      shareBox: false,
      VerifyProduct: {},
      ProductAuthenticationData: null,
      rating: null,
      showDialog: false,
      VerificationMode: "Web",
      QrData: window.location.href,
      VerificationType: 2,
      VerificationId: 0,
      displayBasic: false,
      UserId: "",
      SeqNo: "",
      UserName: "",
      Email: "",
      Mobile: "",
      UserFeedback: "",
      showVideo: true,
      photoIndex: 0,
      isOpen: false,
      socialLinks: "",

      formError: {
        UserName: null,
        Email: null,
        Mobile: null,
        UserFeedback: null,
      },
    };

    this.submitForm = this.submitForm.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onHide = this.onHide.bind(this);
    this.PrintpdfDATA = this.PrintpdfDATA.bind(this);
    this.downloadUpdate = this.downloadUpdate.bind(this);
    //this.openDialog = this.openDialog.bind(this);
    // this.updateRating = this.updateRating.bind(this);
    this.ImgModal = this.ImgModal.bind(this);
    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    const parmUid = urlParams.get("uid");
    const curl = window.location.href;
    this.clsVerifyProduct.QrData = this.state.QrData;
    this.clsVerifyProduct.VerificationType = this.state.VerificationType;
    this.findMe();
  }

  // get reverse geo code
  reverseGeoCode(lat, long) {
    // tslint:disable-next-line:max-line-length
    const requestOptions = {
      method: "GET",
    };
    return fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
      requestOptions
    ).then(this.handleResponse);
    //   return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`);
  }
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          //logout();
          //location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }

  //to trigger api on page call
  componentDidMount() {
    ////console.log("data", this.state.ProductAuthenticationData);
    const caches = window.caches;
    if (window.caches) {
      window.caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => window.caches.delete(name)));
      });
    }
  }

  // get verify product list data
  async GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });
    const authData = await this.PAService.VerifyProduct(productAuthReq);
    //console.log("auth adulteration data", authData);
    this.setState({
      ProductAuthenticationData: authData.data.ResponseData,
    });
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayBasic: false,
    });
  }

  //to validate feedback form
  validateFeedback(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
        } else errors.Email = "";
        break;
      case "Mobile":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Mobile = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.Mobile = "Please enter valid mobile no.";
          } else errors.Mobile = null;
        } else errors.Mobile = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your feedback.";
        } else errors.UserFeedback = "";
        break;

      default:
        break;
    }

    return IsValid;

    // return formFeedIsValid;
  }

  getDate(d) {
    debugger;
    var date = d.split("/");
    var f = new Date(date[2], (parseInt(date[1]) - 1).toString(), date[0]);
    return f;
  }

  ImgModal() {
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg = document.getElementById("img01");
      // captionText.innerHTML = this.alt;
    };

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };
  }

  async submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;

    if (isfrmvalid) {
      const formData = {
        rndmNo: this.state.QRData,
        seqNo: this.state.SeqNo,
      };
      const ResponseData = await this.PAService.VerifyProduct(
        this.state.QRData
      );

      //const seqNumber = this.ScanQRService.ScanQRCode(this.state.EncryptedValue);
      const authResponse = ResponseData.data;
      const authData = authResponse?.ResponseData;
      ////console.log("authData", authData);
      if (authResponse.ResponseCode === "200") {
        this.setState({
          VisibleNo: authData.VisibleNo,
          displayDialog: false,
        });
      } else {
        this.setState({
          displayDialog: true,
          invalidMessage: authResponse.ResponseMessage,
          VisibleNo: "",
        });
      }
    }
  }

  formReset() {
    this.setState({
      UserName: "",
      Email: "",
      Mobile: "",
      UserFeedback: "",
      formError: {
        UserName: null,
        Email: null,
        Mobile: null,
        UserFeedback: null,
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  downloadUpdate(data) {
    const formData = {
      SeqNo: data,
    };
    this.DownloadReportService.CreateDownloadCount(formData).then((data) => {
      const resdata = data.data;

      if (resdata.ResponseMessage == "success") {
        // toast.success("success");
      } else {
      }
    });
  }

  PrintpdfDATA = (rowData) => {
    debugger;
    //console.log("DATA certificate wala!", rowData);
    let QRDATA = window.location.href;

    this.downloadUpdate(rowData.SeqNo);
    let qrdata = this.genQR(QRDATA);

    if (rowData.fakeVerify == "True") {
      if (this.getDate(rowData.CreatedDate) >= new Date("08/27/2023")) {
        this.printUpdatedFakeDocument(rowData, qrdata);
      } else {
        this.printDocumentfakeVerify(rowData, qrdata);
      }
    } else {
      if (this.getDate(rowData.CreatedDate) >= new Date("08/27/2023")) {
        this.printUpdatedDocument(rowData, qrdata);
      } else {
        this.printDocument(rowData, qrdata);
      }
    }
  };

  async printUpdatedFakeDocument(rowData, qrdata) {
    debugger;
    const url = certificateAdultrationUpdated;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );

    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);

    const jpgImage2 = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDimsProdImg = jpgImage2.scale(1.2);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 125.5,
      y: 337.6,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 152,
        y: 224.4,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 175,
        y: 301.1,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 97,
        y: 264,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 493,
        y: 263.9,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 502,
        y: 372.9,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 154.5,
      y: 373,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 468,
        y: 337.8,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 487,
        y: 301.4,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 825,
        y: 120,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 690,
          y: 214,
          width: jpgDimsProdImg.width,
          height: jpgDimsProdImg.height,
          size: 45,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
      );
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    // link.setAttribute("download", "PashminaCertificate.pdf");
    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");

    document.body.appendChild(link);
    link.click();
  }

  genQR(text) {
    let qr = "";
    QRCode.toDataURL(text, function (err, url) {
      qr = url;
    });
    return qr;
  }

  //Label print starts
  async printDocument(rowData, qrdata) {
    const url = productimgpdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );
    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);
    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 125,
      y: 319.3,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 138,
        y: 211.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 87,
        y: 292.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 98,
        y: 265,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 114,
        y: 238.5,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 688,
        y: 347,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 154,
      y: 350,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 642,
        y: 211.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 815,
        y: 211.2,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 807,
        y: 118,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 750,
          y: 250,
          width: jpgDims.width,
          height: jpgDims.height,
          size: 11,
          font: helveticaFont,
          size: 15,
          color: rgb(0, 0, 0),
        }
      );
    }
    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    // link.setAttribute("download", "PashminaCertificate.pdf");
    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");
    document.body.appendChild(link);
    link.click();
  }

  //Label Updated print starts
  async printUpdatedDocument(rowData, qrdata) {
    debugger;
    const url = productimgUpdatedpdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );

    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);

    const jpgImage2 = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDimsProdImg = jpgImage2.scale(1.13);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 125,
      y: 316.5,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 152,
        y: 218.3,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 175,
        y: 285,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 98,
        y: 253.1,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 473,
        y: 253.3,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 482,
        y: 350,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 154,
      y: 350,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 448,
        y: 316.5,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 467,
        y: 285.1,
        size: 11,
        font: helveticaFont,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 815,
        y: 118,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 690,
          y: 202,
          width: jpgDimsProdImg.width,
          height: jpgDimsProdImg.height,
          size: 45,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
      );
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");

    document.body.appendChild(link);
    link.click();
  }

  async printDocumentfakeVerify(rowData, qrdata) {
    const url = productimg2pdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(
      (StandardFonts.Helvetica = "Helvetica")
    );

    const jpgImage = await pdfDoc.embedPng(qrdata.split("base64,")[1]);
    const jpgDims = jpgImage.scale(0.5);
    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(rowData.ProductCode + "-" + rowData.BarCode, {
      x: 126,
      y: 334,
      size: 11,
      font: helveticaFont,
      size: 13,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.TestPerformed == null || rowData.TestPerformed == undefined
        ? ""
        : rowData.TestPerformed,
      {
        x: 140,
        y: 225,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Colour == null || rowData.Colour == undefined
        ? ""
        : rowData.Colour,
      {
        x: 89,
        y: 307,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Weaving == null || rowData.Weaving == undefined
        ? ""
        : rowData.Weaving,
      {
        x: 100,
        y: 279,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Embroidery == null || rowData.Embroidery == undefined
        ? ""
        : rowData.Embroidery,
      {
        x: 115,
        y: 252,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.CreatedDate == null || rowData.CreatedDate == undefined
        ? ""
        : rowData.CreatedDate,
      {
        x: 690,
        y: 362.5,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(rowData.BatchCode + "/" + rowData.SupplierCode, {
      x: 155.5,
      y: 365,
      size: 11,
      font: helveticaFont,
      size: 12,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      rowData.Weight == null || rowData.Weight == undefined
        ? ""
        : rowData.Weight,
      {
        x: 642,
        y: 225,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      rowData.Dimensions == null || rowData.Dimensions == undefined
        ? ""
        : rowData.Dimensions,
      {
        x: 816,
        y: 225,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawImage(
      (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == null ||
        (await pdfDoc.embedPng(qrdata.split("base64,")[1])) == undefined
        ? ""
        : await pdfDoc.embedPng(qrdata.split("base64,")[1]),
      {
        x: 800,
        y: 118,
        width: jpgDims.width,
        height: jpgDims.height,
        size: 11,
        font: helveticaFont,
        size: 13,
        color: rgb(0, 0, 0),
      }
    );

    if (rowData.ProductImgBase64) {
      firstPage.drawImage(
        rowData.ProductImg.includes(".png") == true
          ? await pdfDoc.embedPng(rowData.ProductImgBase64)
          : await pdfDoc.embedJpg(rowData.ProductImgBase64),
        {
          x: 750,
          y: 265,
          width: jpgDims.width,
          height: jpgDims.height,
          size: 11,
          font: helveticaFont,
          size: 13,
          color: rgb(0, 0, 0),
        }
      );
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    // link.setAttribute("download", "PashminaAdulterationCertificate.pdf");

    const supdata = rowData.SupplierCode;
    link.setAttribute("download", supdata + ".pdf");

    document.body.appendChild(link);
    link.click();
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateFeedback(value, name);
    this.setState({ errors, [name]: value });
  }
  onHide() {
    this.props.onHideShow(false);
  }

  // openDialog() {
  //   this.props.onHideShow(true);
  // }

  //to find  location
  //to find  location
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
        },
        (denied) => {
          this.GetVerifyProduct(this.clsVerifyProduct);
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    } else {
      this.GetVerifyProduct(this.clsVerifyProduct);
    }
  }
  //to get exact longitude and latitude
  showPosition(position) {
    this.clsVerifyProduct.Userlongitude = position.coords.longitude;
    this.clsVerifyProduct.Userlatitude = position.coords.latitude;
    this.reverseGeocodeLatLngAndVerifyProduct();
  }
  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Userlatitude,
      this.clsVerifyProduct.Userlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.City = res.address?.state_district;
        this.clsVerifyProduct.State = res.address?.state;
        this.clsVerifyProduct.Country = res.address?.country;
        this.clsVerifyProduct.Pincode = res.address?.postcode;
        this.clsVerifyProduct.Address = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    let links = null;
    let share = null;
    return (
      <>
        <section className="">
          <div>
            <nav className="navbar navbar-dark navbar-expand-sm epch-bg fixed-top">
              <div className="row container-fluid">
                <div className="col-md-6 navbar-brand pa-logo">
                  <img
                    src={Logo}
                    alt="EPCH"
                    style={{ width: "80px", height: "auto" }}
                  />
                </div>
                <div className="col-md-6 text-right" style={{ color: "black" }}>
                  <h4 className="pa-nav"> PASHMINA CERTIFICATION CENTER</h4>
                </div>

                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button> */}
              </div>
            </nav>

            {/* Page Content */}
            <div className="container-fluid pa">
              <div className="card border-0  pap  ">
                {this.state.ProductAuthenticationData !== null &&
                this.state.ProductAuthenticationData.ResponseCode === "200" ? (
                  <div className="row genuine-img ">
                    {this.state.ProductAuthenticationData.fakeVerify ===
                    "True" ? (
                      <div>
                        <div className="col-md-12 ">
                          <img
                            src={Fake}
                            className="mx-auto d-block py-2"
                            style={{ width: "150px", marginBottom: "20px" }}
                          />
                        </div>

                        <div className="row d-flex align-items-center">
                          <div className="col-md-12 text-center">
                            <div className="row">
                              <div className="col-md-6 fake-icone">
                                <img src={invalid} style={{ width: "50px" }} />
                              </div>
                              <div className="col-md-6 state">
                                <span className="status">Status</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row d-flex align-items-center">
                          <div className="col-md-12 text-center">
                            <span className="invalid">
                              <p>
                                {" "}
                                It is an adultrated product and failed at the
                                set parameters of Pashmina Certifications at
                                PCC, Dehradun!
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="col-md-12 ">
                          <img
                            src={verified}
                            className="mx-auto d-block py-2"
                            style={{ width: "150px", marginBottom: "20px" }}
                          />
                        </div>
                        <div className="container-fluid  text-center status-align">
                          <div className="row">
                            <div className="col-md-4 genuine-icon status-icon">
                              <img
                                src={valid}
                                className="mb-2"
                                style={{ width: "50px" }}
                              />
                            </div>
                            <div className="col-md-8 statuse">
                              <span className="status">Status : </span>
                              <span className="Genuine">Genuine Product!</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <p className="thankfor-buy">
                                Thank you for buying Genuine Product.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="container table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Seq No.</th>
                            <th>Unique Code</th>
                            <th>Product</th>
                            <th>Colour</th>
                            <th>Weaving</th>
                            <th>Embroidery</th>
                            <th>Test Performed</th>
                            <th>Weight</th>
                            <th>Dimensions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData.SeqNo
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData
                                    .UniqueCode
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData
                                    .ProductCode
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData.Colour
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData.Weaving
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData
                                    .Embroidery
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData
                                    .TestPerformed
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData.Weight
                                : ""}
                            </td>
                            <td>
                              {this.state.ProductAuthenticationData
                                ? this.state.ProductAuthenticationData
                                    .Dimensions
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-12 text-center ">
                      <div className="login status-mobile py-2">
                        <div>
                          <div id="wrap2 download d-booton-download">
                            <a className="btn-slide2">
                              {/* <span className="circle2">
                                <i className="fa fa-download"></i>
                              </span>
                              <span className="title2">
                                {" "}
                                Download Certificate
                              </span>
                              <span
                                onClick={() =>
                                  this.PrintpdfDATA(
                                    this.state.ProductAuthenticationData
                                  )
                                }
                                className="title-hover2"
                              >
                                Click here
                              </span> */}
                              <Button
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "black",
                                  marginTop: "-5px",
                                  boxShadow: "none",
                                }}
                                onClick={() =>
                                  this.PrintpdfDATA(
                                    this.state.ProductAuthenticationData
                                  )
                                }
                              >
                                <i className="fa fa-download mr-2"></i> Download
                                Certificate
                              </Button>
                            </a>
                          </div>
                          {/* <Button
                            onClick={() =>
                              this.PrintpdfDATA(
                                this.state.ProductAuthenticationData
                              )
                            }
                          >
                            Download Certificate
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.ProductAuthenticationData?.ResponseCode ===
                    "400" ? (
                      <div className="row genuine-img ">
                        <div className="col-md-12 ">
                          <img
                            src={Fake}
                            className="mx-auto d-block py-2"
                            style={{ width: "150px", marginBottom: "20px" }}
                          />
                        </div>
                        <div className="col-md-12 ">
                          <div className="login d-flex align-items-center py-2 ">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-2 genuine-icon">
                                  <img
                                    src={invalid}
                                    className="py-2"
                                    style={{ width: "50px" }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <span className="status">Status: </span>

                                  <span className="invalid">
                                    This Pashmina Shawl is not verified yet !
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <footer className="bg-dark1 text-center text-white">
            {/* Grid container */}
            <div className="container-fluid p-1 pb-0">
              <div className="row">
                <div className="col-md-12 social-icon text-center">
                  {/* Facebook */}
                  <a
                    className="btn btn-outline-light btn-floating m-1"
                    target="_blank"
                    href="https://www.facebook.com/epchindia"
                    role="button"
                  >
                    <i className="fa fa-facebook-f" />
                  </a>
                  {/* Twitter */}
                  <a
                    className="btn btn-outline-light btn-floating m-1"
                    target="_blank"
                    href="https://twitter.com/epchindia"
                    role="button"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                  {/* Google */}
                  <a
                    className="btn btn-outline-light btn-floating m-1"
                    target="_blank"
                    href="https://in.pinterest.com/epchindia/"
                    role="button"
                  >
                    <i className="fa fa-pinterest-p" />
                  </a>
                  {/* youtube */}
                  <a
                    className="btn btn-outline-light btn-floating m-1"
                    target="_blank"
                    href="https://www.youtube.com/user/ihgfdelhifair"
                    role="button"
                  >
                    <i className="fa fa-youtube" />
                  </a>
                  {/* Linkediv*/}
                  <a
                    className="btn btn-outline-light btn-floating m-1"
                    target="_blank"
                    href="https://www.linkedin.com/company/epchindia/"
                    role="button"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                  {/* Instagram */}
                  <a
                    className="btn btn-outline-light btn-floating m-1"
                    target="_blank"
                    href="https://www.instagram.com/ihgfdelhifair/?hl=en"
                    role="button"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </div>
                {/* Section: Social media */}
              </div>
            </div>
            {/* Grid container */}
          </footer>
          {/* End of .container */}
        </section>
      </>
    );
  }
}
